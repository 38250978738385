import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import styles from "./Upload.module.css";
import Title from "../../Components/Title/Title";
import { useContext, useState } from "react";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "firebase/storage";
import "firebase/database";

// import { DataContext } from "../../Contexts/DataContext";
import { LoginContext } from "../../Contexts/LoginContext";

const Upload = () => {
  const { loginHandeler } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);

  const [listingPageFormData, setListingPageFormData] = useState({
    carTitle: "",
    fuelType: "",
    transmission: "",
    odometerReading: "",
    askingPrice: "",
    registration: "",
    luxury: "",
    sold: "",
    bodyType: "",
    imageUrl: null,
  });
  // useState();
  const [descriptionPageFormData, setDescriptionPageFormData] = useState({
    carTitle: "",
    fuelType: "",
    transmission: "",
    odometerReading: "",
    condition: "",
    registration: "",
    manufacturing: "",
    colorExterior: "",
    colorInterior: "",
    registrationType: "",
    insuranceType: "",
    insuranceUpto: "",
    assemblyType: "",
    warranty: "",
    location: "",
    features: "",
    askingPrice: "",
    descriptionImageUrl: [],
    interiorImageUrl: [],
    exteriorImageUrl: [],
  });
  // const [landingPageSliderData, setLandingPageSliderData] = useState([]);
  const [oldStockFormData, setOldStockFormData] = useState({
    carTitle: "",
    fuelType: "",
    transmission: "",
    odometerReading: "",
    askingPrice: "",
    registration: "",
    luxury: "",
    sold: "",
    bodyType: "",
    imageUrl: null,
  });
  const logoutHandeler = () => {
    loginHandeler(false);
  };

  const getEmptyFields = (formData) => {
    // Filter out fields with empty values
    const emptyFields = Object.entries(formData).filter(([key, value]) => {
      // Check if the value is an array and if its length is zero
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      // Check if the value is a string and if its length is zero
      return typeof value === "string" && value.length === 0;
    });

    // Convert the filtered result back to an object
    const result = emptyFields.map(([key]) => key).join(", ");

    alert(result);
    console.log(result);

    return result;
  };

  // Example usage:
  // const emptyFields = getEmptyFields(descriptionPageFormData);

  // console.log(emptyFields);

  const handleListingPageInputChange = (e, field) => {
    setListingPageFormData({
      ...listingPageFormData,
      [field]: e.target.value,
    });
    // console.log(listingPageFormData);
  };
  const handleOldStockInputChange = (e, field) => {
    setOldStockFormData({
      ...oldStockFormData,
      [field]: e.target.value,
    });
  };
  const handleDescriptionPageInputChange = (e, field) => {
    setDescriptionPageFormData({
      ...descriptionPageFormData,
      [field]: e.target.value,
    });
  };
  const handleListingPageImageUpload = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const storageRef = ref(
      storage,
      `carImages/${listingPageFormData.carTitle}/${file.name}`
    );
    await uploadBytesResumable(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    // console.log(imageUrl);

    setIsLoading(false);

    setListingPageFormData({
      ...listingPageFormData,
      imageUrl,
    });
  };
  const handleDescriptionPageImageUpload_description = async (e) => {
    setIsLoading(true);
    // This will be an array of promises, one for each file being uploaded
    const uploadPromises = [];

    // This will be an array of the download URLs of the uploaded files
    const imageUrls = [];

    // Files is a FileList object (similar to an array)
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(
        storage,
        `carDescription/${descriptionPageFormData.carTitle}/${file.name}`
      );

      // Upload file and store promise
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef)) // Get download URL after upload is complete
        .then((downloadURL) => {
          // console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL); // Add download URL to the array
        });

      // Add the current upload promise to the array
      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("images uploaded successfully");
      // console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setDescriptionPageFormData({
        ...descriptionPageFormData,
        descriptionImageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDescriptionPageImageUpload_interior = async (e) => {
    setIsLoading(true);
    // This will be an array of promises, one for each file being uploaded
    const uploadPromises = [];

    // This will be an array of the download URLs of the uploaded files
    const imageUrls = [];

    // Files is a FileList object (similar to an array)
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(
        storage,
        `carInterior/${descriptionPageFormData.carTitle}/${file.name}`
      );

      // Upload file and store promise
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef)) // Get download URL after upload is complete
        .then((downloadURL) => {
          // console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL); // Add download URL to the array
        });

      // Add the current upload promise to the array
      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("Images uploaded successfully");
      // console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setDescriptionPageFormData({
        ...descriptionPageFormData,
        interiorImageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDescriptionPageImageUpload_exterior = async (e) => {
    setIsLoading(true);
    // This will be an array of promises, one for each file being uploaded
    const uploadPromises = [];

    // This will be an array of the download URLs of the uploaded files
    const imageUrls = [];

    // Files is a FileList object (similar to an array)
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(
        storage,
        `carExterior/${descriptionPageFormData.carTitle}/${file.name}`
      );

      // Upload file and store promise
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef)) // Get download URL after upload is complete
        .then((downloadURL) => {
          // console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL); // Add download URL to the array
        });

      // Add the current upload promise to the array
      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("Images uploaded successfully");
      // console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setDescriptionPageFormData({
        ...descriptionPageFormData,
        exteriorImageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOldStockImageUpload = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const storageRef = ref(
      storage,
      `oldStock/${oldStockFormData.carTitle}/${file.name}`
    );
    await uploadBytesResumable(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    // console.log(imageUrl);
    setIsLoading(false);

    setOldStockFormData({
      ...oldStockFormData,
      imageUrl,
    });
  };
  const handlelistingPageDataSubmit = async (e) => {
    const {
      carTitle,
      fuelType,
      transmission,
      odometerReading,
      askingPrice,
      registration,
      luxury,
      sold,
      bodyType,
      imageUrl,
    } = listingPageFormData;

    e.preventDefault();
    if (
      carTitle.length > 0 &&
      fuelType.length > 0 &&
      transmission.length > 0 &&
      odometerReading.length > 0 &&
      askingPrice.length > 0 &&
      registration.length > 0 &&
      luxury.length > 0 &&
      sold.length > 0 &&
      bodyType.length > 0 &&
      imageUrl != null
    ) {
      const response = await fetch(
        `https://theautocops-5ac27-default-rtdb.firebaseio.com/productListingPageData.json`,
        {
          method: "POST",
          body: JSON.stringify(listingPageFormData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      // console.log(responseData);
      // This will log the unique key generated by Firebase for the new data.
      setListingPageFormData({
        carTitle: "",
        fuelType: "",
        transmission: "",
        odometerReading: "",
        askingPrice: "",
        registration: "",
        luxury: "",
        sold: "",
        bodyType: "",
        imageUrl: null,
      });
      alert("Data submitted successfully");
    } else {
      alert("please fill all the details.");
    }
  };
  const handleDescriptionPageDataSubmit = async (e) => {
    const {
      carTitle,
      fuelType,
      transmission,
      odometerReading,
      condition,
      registration,
      manufacturing,
      colorExterior,
      colorInterior,
      registrationType,
      insuranceType,
      insuranceUpto,
      assemblyType,
      warranty,
      location,
      features,
      askingPrice,
      descriptionImageUrl,
      interiorImageUrl,
      exteriorImageUrl,
    } = descriptionPageFormData;

    e.preventDefault();

    if (
      carTitle.length > 0 &&
      fuelType.length > 0 &&
      transmission.length > 0 &&
      odometerReading.length > 0 &&
      condition.length > 0 &&
      registration.length > 0 &&
      manufacturing.length > 0 &&
      colorExterior.length > 0 &&
      colorInterior.length > 0 &&
      // registration.length > 0 &&
      registrationType.length > 0 &&
      insuranceType.length > 0 &&
      insuranceUpto.length > 0 &&
      assemblyType.length > 0 &&
      warranty.length > 0 &&
      location.length > 0 &&
      features.length > 0 &&
      askingPrice.length > 0 &&
      descriptionImageUrl.length > 0 &&
      interiorImageUrl.length > 0 &&
      exteriorImageUrl.length > 0
    ) {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/productDescriptionPageData.json",
        {
          method: "POST",
          body: JSON.stringify(descriptionPageFormData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      // console.log(responseData);
      // This will log the unique key generated by Firebase for the new data.
      alert(
        `Data submitted successfully for ${descriptionPageFormData.carTitle}`
      );

      setDescriptionPageFormData({
        carTitle: "",
        fuelType: "",
        transmission: "",
        odometerReading: "",
        condition: "",
        registration: "",
        manufacturing: "",
        colorExterior: "",
        colorInterior: "",
        registrationType: "",
        insuranceType: "",
        insuranceUpto: "",
        assemblyType: "",
        warranty: "",
        location: "",
        features: "",
        askingPrice: "",
        descriptionImageUrl: [],
        interiorImageUrl: [],
        exteriorImageUrl: [],
      });
    } else {
      getEmptyFields(descriptionPageFormData);
      alert("Please fill in all the details");
    }
  };
  const handleOldStockDataSubmit = async (e) => {
    const {
      carTitle,
      fuelType,
      transmission,
      odometerReading,
      askingPrice,
      registration,
      imageUrl,
      luxury,
      sold,
      bodyType,
    } = oldStockFormData;
    // console.log(oldStockFormData);
    e.preventDefault();
    if (
      carTitle.length > 0 &&
      fuelType.length > 0 &&
      transmission.length > 0 &&
      odometerReading.length > 0 &&
      askingPrice.length > 0 &&
      registration.length > 0 &&
      luxury.length > 0 &&
      sold.length > 0 &&
      bodyType.length > 0 &&
      imageUrl != null
    ) {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/oldStock.json",
        {
          method: "POST",
          body: JSON.stringify(oldStockFormData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      // console.log(responseData);
      // This will log the unique key generated by Firebase for the new data.
      setOldStockFormData({
        carTitle: "",
        fuelType: "",
        transmission: "",
        odometerReading: "",
        askingPrice: "",
        registration: "",
        luxury: "",
        sold: "",
        bodyType: "",
        imageUrl: null,
      });
      alert("Data submitted successfully");
    } else {
      alert("please fill all the details.");
    }
  };
  const handleContactUsBannerImageUpload = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const storageRef = ref(storage, `aboutUsBanner/${file.name}`);
    await uploadBytesResumable(storageRef, file);
    const imageUrl = await getDownloadURL(storageRef);
    // console.log(imageUrl);
    setIsLoading(false);
  };
  const handleContactUsBannerDataSubmit = async (e) => {};

  return (
    <>
      <ScrollToTopOnMount />
      <section className={`${styles.section1} ${styles.ow}`}>
        <Title bg="ow">data upload page for theautocops</Title>
        <div>
          <button onClick={logoutHandeler}>Logout</button>
        </div>
        <div>
          <h3 className={styles.h3}>
            <span className={styles.span}>note:</span>
          </h3>
          <li className={styles.li}>
            {" "}
            make sure the <span className={styles.span}>car title</span> is the
            same for both{" "}
            <span className={styles.span}>
              stock page & stock description page
            </span>{" "}
            (capitalisation, symbols, numbers etc)
          </li>
          <li className={styles.li}>
            keep the object{" "}
            <span className={styles.span}>
              {" "}
              (here car) position centre in the image
            </span>{" "}
            while uploading.
          </li>
        </div>
      </section>
      {/* PLP UPLOAD FORM */}
      <section className={`${styles.section2}`}>
        <Title>stock page</Title>
        <div>
          <input
            onChange={(e) => handleListingPageInputChange(e, "carTitle")}
            type="text"
            value={listingPageFormData.carTitle}
            placeholder="Car Title"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "fuelType")}
            type="text"
            value={listingPageFormData.fuelType}
            placeholder="Fuel Type"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "transmission")}
            type="text"
            value={listingPageFormData.transmission}
            placeholder="Transmission"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "odometerReading")}
            type="text"
            value={listingPageFormData.odometerReading}
            placeholder="Odometer Reading"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "askingPrice")}
            type="text"
            value={listingPageFormData.askingPrice}
            placeholder="Asking Price"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "registration")}
            type="text"
            value={listingPageFormData.registration}
            placeholder="Registration Date eg:2019 JAN"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "luxury")}
            type="text"
            value={listingPageFormData.luxury}
            placeholder="Luxury: yes/no"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "sold")}
            type="text"
            value={listingPageFormData.sold}
            placeholder="Sold: true/false"
          />
          <input
            onChange={(e) => handleListingPageInputChange(e, "bodyType")}
            type="text"
            value={listingPageFormData.bodyType}
            placeholder="body type: sedan/suv/muv/sports etc"
          />
          <label className={styles.label} htmlFor="fileInput">
            Upload single Front Facing Image:
          </label>
          <input
            onChange={handleListingPageImageUpload}
            type="file"
            accept="image/*"
            placeholder="Upload Front Facing Image"
          />
          <div>
            <button disabled={isLoading} onClick={handlelistingPageDataSubmit}>
              {isLoading ? "Uploading..." : "Submit"}
            </button>
          </div>
        </div>
      </section>
      {/* PDP UPLOAD FORM */}
      <section className={`${styles.section3} ${styles.ow}`}>
        <Title bg="ow">stock description page</Title>
        <div>
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "carTitle")}
            type="text"
            value={descriptionPageFormData.carTitle}
            placeholder="Car Title"
          />
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "fuelType")}
            type="text"
            placeholder="Fuel Type"
            value={descriptionPageFormData.fuelType}
          />
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "transmission")
            }
            type="text"
            placeholder="Transmission"
            value={descriptionPageFormData.transmission}
          />
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "odometerReading")
            }
            type="text"
            value={descriptionPageFormData.odometerReading}
            placeholder="Odometer Reading"
          />
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "condition")}
            type="text"
            value={descriptionPageFormData.condition}
            placeholder="Condition: used/new"
          />
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "registration")
            }
            type="text"
            value={descriptionPageFormData.registration}
            placeholder="registration date eg: 2019 JAN"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "manufacturing")
            }
            type="text"
            value={descriptionPageFormData.manufacturing}
            placeholder="manufacturing year eg: 2019"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "colorExterior")
            }
            type="text"
            value={descriptionPageFormData.colorExterior}
            placeholder="Exterior Color"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "colorInterior")
            }
            type="text"
            value={descriptionPageFormData.colorInterior}
            placeholder="Interior Color"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "registrationType")
            }
            type="text"
            value={descriptionPageFormData.registrationType}
            placeholder="Registration Type: Indivisual/Corporate"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "insuranceType")
            }
            type="text"
            value={descriptionPageFormData.insuranceType}
            placeholder="Insurance Type eg: zero dep"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "insuranceUpto")
            }
            type="text"
            value={descriptionPageFormData.insuranceUpto}
            placeholder="Insurance Upto eg: 2021 JAN"
          />{" "}
          <input
            onChange={(e) =>
              handleDescriptionPageInputChange(e, "assemblyType")
            }
            type="text"
            value={descriptionPageFormData.assemblyType}
            placeholder="Assembly Type eg: CKD/CBU"
          />{" "}
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "warranty")}
            type="text"
            value={descriptionPageFormData.warranty}
            placeholder="Warranty eg: upto 2024/NA"
          />{" "}
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "location")}
            type="text"
            value={descriptionPageFormData.location}
            placeholder="Location eg: Kalyani nagar/Wakad"
          />{" "}
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "features")}
            type="text"
            value={descriptionPageFormData.features}
            placeholder="Features eg: sunroof, reverse camera, moonroof etc"
          />{" "}
          <input
            onChange={(e) => handleDescriptionPageInputChange(e, "askingPrice")}
            type="text"
            value={descriptionPageFormData.askingPrice}
            placeholder="Asking Price"
          />
          <h3>Upload Description Images (multiple)</h3>
          <input
            onChange={handleDescriptionPageImageUpload_description}
            type="file"
            multiple
            accept="image/*"
            placeholder="Upload Description Images"
          />
          <h3>Upload Interior Images (multiple)</h3>
          <input
            onChange={handleDescriptionPageImageUpload_interior}
            type="file"
            multiple
            accept="image/*"
            placeholder="Upload Interior Images"
          />{" "}
          <h3>Upload Exterior Images (multiple)</h3>
          <input
            onChange={handleDescriptionPageImageUpload_exterior}
            type="file"
            multiple
            accept="image/*"
            placeholder="Upload Exterior Images"
          />
          <div>
            <button
              onClick={handleDescriptionPageDataSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Uploading..." : "Submit"}
            </button>
          </div>
        </div>
      </section>
      {/* old stock data upload */}
      <section className={`${styles.section5} ${styles.w}`}>
        <Title>old stock data upload</Title>
        <div>
          <input
            onChange={(e) => handleOldStockInputChange(e, "carTitle")}
            type="text"
            value={oldStockFormData.carTitle}
            placeholder="Car Title"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "fuelType")}
            type="text"
            value={oldStockFormData.fuelType}
            placeholder="Fuel Type"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "transmission")}
            type="text"
            value={oldStockFormData.transmission}
            placeholder="Transmission"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "odometerReading")}
            type="text"
            value={oldStockFormData.odometerReading}
            placeholder="Odometer Reading"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "askingPrice")}
            type="text"
            value={oldStockFormData.askingPrice}
            placeholder="Asking Price"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "registration")}
            type="text"
            value={oldStockFormData.registration}
            placeholder="Registration Date eg:2019 JAN"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "luxury")}
            type="text"
            value={oldStockFormData.luxury}
            placeholder="Luxury: yes/no"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "sold")}
            type="text"
            value={oldStockFormData.sold}
            placeholder="Sold: true/false"
          />
          <input
            onChange={(e) => handleOldStockInputChange(e, "bodyType")}
            type="text"
            value={oldStockFormData.bodyType}
            placeholder="body type: sedan/suv/muv/sports etc"
          />
          <label className={styles.label} htmlFor="fileInput">
            Upload single Front Facing Image:
          </label>
          <input
            onChange={handleOldStockImageUpload}
            type="file"
            accept="image/*"
            placeholder="Upload Front Facing Image"
          />
          <div>
            <button disabled={isLoading} onClick={handleOldStockDataSubmit}>
              {isLoading ? "Uploading..." : "Submit"}
            </button>
          </div>
        </div>
      </section>
      {/* Banner Images */}
      <section className={`${styles.section4} ${styles.ow}`}>
        <Title bg="ow">Banner Images</Title>
        <div>
          <label className={styles.label} htmlFor="fileInput">
            contact us:
          </label>
          <input
            onChange={handleContactUsBannerImageUpload}
            type="file"
            accept="image/*"
            placeholder="Upload Front Facing Image"
          />
        </div>
        <div>
          <button
            onClick={handleContactUsBannerDataSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Uploading..." : "Submit"}
          </button>
        </div>
      </section>
    </>
  );
};
export default Upload;
