import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/NavBar/Navbar";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import styles from "./CalculateEmi.module.css";
import Title from "../../Components/Title/Title";
import { useState, useEffect, useContext } from "react";
import banner from "../../Images/CalculateEmi/banner.png";
import FaqSection from "../../Components/FaqSection/FaqSection";
import PieChart from "../../Components/LoanCalculator/PieChart";
import Header from "../../Components/Navbar_2/Header";
import { DataContext } from "../../Contexts/DataContext";

const CalculateEmi = () => {
  const { state, dispatch } = useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  const [data, setData] = useState({
    amount: 1000000,
    years: 0,
    interest: 0,
    emi: 0,
    totalInterest: 0,
  });

  function calculateEMI(principal, annualInterestRate, tenureYears) {
    // Convert annual interest rate to monthly and percentage to decimal
    const monthlyInterestRate = annualInterestRate / 12 / 100;

    // Convert loan tenure to months
    const tenureMonths = tenureYears * 12;

    // Calculate EMI using the formula
    const calculatedEmi =
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, tenureMonths)) /
      (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

    setData((prevData) => ({
      ...prevData,
      emi: calculatedEmi.toFixed(2),
    }));
    // console.log(data.emi);
    const totalInterest = calculatedEmi * tenureMonths - principal;

    setData((prevData) => ({
      ...prevData,
      totalInterest: totalInterest.toFixed(2),
    }));
    return calculatedEmi.toFixed(2);
    // Round to two decimal places
  }

  return (
    <>
      <ScrollToTopOnMount />
      {/* <Navbar /> */}
      <Header />
      <section className={styles.section1}>
        <img className={styles.image} src={banner} />
      </section>
      <section className={`${styles.section2} ${styles.ow}`}>
        <div className={styles.container}>
          <Title bg="ow">Finance With Ease, Drive Away With Confidence</Title>
        </div>
        <div className={styles.container}>
          <p>
            Use our customizable EMI calculator to estimate monthly payments on
            your next luxury vehicle. Our finance experts simplify the process
            so you can shop stress-free, understand all options, and drive home
            your dream car. Let's find the perfect payment plan for your budget
            today!
          </p>
        </div>
      </section>
      <section className={styles.section3}>
        <div className={styles.container}>
          <Title>
            Find Your Fit: Calculate a Personalized EMI in Under 60 Seconds
          </Title>
        </div>
      </section>
      <div className={styles.calculatorContainer}>
        {" "}
        <section className={`${styles.section4} ${styles.ow}`}>
          <PieChart chartData={data} />
        </section>
        <section className={styles.section3}>
          <form>
            <div>
              {" "}
              <div>
                <label htmlFor="">Principle (&#x20B9;)</label>
                <input
                  value={data.amount}
                  onChange={(event) =>
                    setData({ ...data, amount: event.target.value })
                  }
                  className={styles.input}
                  type="tel"
                  placeholder="Loan amount"
                />{" "}
              </div>
              <div>
                <label htmlFor="">Tenure (Years)</label>
                <input
                  value={data.years}
                  onChange={(event) =>
                    setData({ ...data, years: event.target.value })
                  }
                  className={styles.input}
                  type="tel"
                  placeholder="Years"
                />{" "}
              </div>
              <div>
                <label htmlFor="">Interest (%)</label>
                <input
                  value={data.interest}
                  onChange={(event) =>
                    setData({ ...data, interest: event.target.value })
                  }
                  className={styles.input}
                  type="tel"
                  placeholder="Interest %"
                />{" "}
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    calculateEMI(data.amount, data.interest, data.years);
                  }}
                  className={styles.button}
                >
                  Calculate
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
      {/* <FaqSection /> */}
      <Footer />
    </>
  );
};
export default CalculateEmi;
