import React, { useState, useEffect, useContext } from "react";
import styles from "./AdminPanel.module.css";
import Title from "../../Components/Title/Title";
import { useNavigate } from "react-router-dom";
import { unparse } from "papaparse";
import Footer from "../../Components/Footer/Footer";
import { LoginContext } from "../../Contexts/LoginContext";
import Accordion from "../../Components/Accordian/Accordian";

const AdminPanel = () => {
  const { loginHandeler } = useContext(LoginContext);
  const navigate = useNavigate();
  const [contactUsFormData, setContactUsFormData] = useState([]);
  const [carEnquiryFormData, setCarEnquiryFormData] = useState([]);
  const [carServiceFormData, setCarServiceFormData] = useState([]);
  const [carDetailingFormData, setCarDetailingFormData] = useState([]);
  const [financingFormData, setFinancingFormData] = useState([]);
  const [insuranceFormData, setinsuranceFormData] = useState([]);
  const [sellYourCarFormData, setSellYourCarFormData] = useState([]);
  const [newsletterEmailFormData, setNewsletterEmailFormData] = useState([]);

  const [loading, setLoading] = useState(true);

  const logoutHandler = () => {
    // navigate("/login");
    loginHandeler(false);
  };

  const getContactUsFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/contactUs.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setContactUsFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCarEnquiryFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/productDescriptionPageFormData.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setCarEnquiryFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCarServiceFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/carServiceData.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setCarServiceFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getCarDetailingFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/carDetailingData.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setCarDetailingFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFinancingFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/financingData.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setFinancingFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/insuranceData.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setinsuranceFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getSellYourCarFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/sellYourCar.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setSellYourCarFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getNewsletterEmailFormData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/newsletterEmail.json"
      );
      if (response.ok) {
        const data = await response.json();
        const dataArray = Object.values(data).reverse();
        // console.log(dataArray);
        setNewsletterEmailFormData(dataArray);
        // setData(Object.values(data));
        // Convert the object into an array
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadCSV = (
    data,
    filename = "Csv Data.csv",
    openInNewTab = false
  ) => {
    const csv = unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // if (openInNewTab) {
    //   window.open(`data:text/csv;charset=utf-8,${escape(csv)}`);
    // } else {
    //   const a = document.createElement("a");
    //   a.setAttribute("hidden", "");
    //   a.setAttribute("href", url);
    //   a.setAttribute("download", filename);
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // }

    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const contactUsForm = (
    <ul>
      {contactUsFormData.map((item) => (
        <li key={item.name}>
          <strong>Name:</strong> {item.name}
          <br />
          <strong>Number:</strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email:</strong> {item.email}
          <br />
          <strong>Requirements:</strong> {item.requirements}
        </li>
      ))}
    </ul>
  );

  const carEnquiryForm = (
    <ul>
      {carEnquiryFormData.map((item) => (
        <li key={item.name}>
          <strong>Name:</strong> {item.name}
          <br />
          <strong>Number:</strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email:</strong> {item.email}
          <br />
          <strong>Requirements:</strong> {item.requirements}
        </li>
      ))}
    </ul>
  );

  const carServiceForm = (
    <ul>
      {carServiceFormData.map((item) => (
        <li key={item.name}>
          <strong>Name:</strong> {item.name}
          <br />
          <strong>Number:</strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email:</strong> {item.email}
          <br />
          <strong>Requirements:</strong> {item.requirements}
          <br />
          <strong>Images: </strong>{" "}
          {item.imageUrl
            ? item.imageUrl.map((url, key) => (
                <li style={{ display: "inline", margin: "0rem 0.25rem" }}>
                  <a target="_blank" href={url}>
                    img-{key + 1}{" "}
                  </a>{" "}
                </li>
              ))
            : "no images"}
        </li>
      ))}
    </ul>
  );

  const carDetailingForm = (
    <ul>
      {carDetailingFormData.map((item) => (
        <li key={item.name}>
          <strong>Name:</strong> {item.name}
          <br />
          <strong>Number:</strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email:</strong> {item.email}
          <br />
          <strong>Requirements:</strong> {item.requirements}
          <br />
          <strong>Images: </strong>{" "}
          {item.imageUrl
            ? item.imageUrl.map((url, key) => (
                <li style={{ display: "inline" }}>
                  <a target="_blank" href={url}>
                    img-{key + 1}{" "}
                  </a>{" "}
                </li>
              ))
            : "no images"}
        </li>
      ))}
    </ul>
  );

  const financingForm = (
    <ul>
      {financingFormData.map((item) => (
        <li key={item.name}>
          <strong>Name:</strong> {item.name}
          <br />
          <strong>Number:</strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email:</strong> {item.email}
          <br />
          <strong>Requirements:</strong> {item.requirements}
        </li>
      ))}
    </ul>
  );

  const InsuranceForm = (
    <ul>
      {insuranceFormData.map((item) => (
        <li key={item.name}>
          <strong>Name:</strong> {item.name}
          <br />
          <strong>Number:</strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email:</strong> {item.email}
          <br />
          <strong>Requirements:</strong> {item.requirements}
          <br />
          <strong>Images: </strong>{" "}
          {item.imageUrl
            ? item.imageUrl.map((url, key) => (
                <li style={{ display: "inline" }}>
                  <a target="_blank" href={url}>
                    img-{key + 1}{" "}
                  </a>{" "}
                </li>
              ))
            : "no images"}
        </li>
      ))}
    </ul>
  );

  const sellYourCarForm = (
    <ul>
      {sellYourCarFormData.map((item) => (
        <li key={item.name}>
          <strong>Brand: </strong>
          {item.brand}
          <br />
          <strong>Model: </strong>
          {item.model}
          <br />
          <strong>Variant: </strong>
          {item.variant}
          <br />
          <strong>Name: </strong> {item.name}
          <br />
          <strong>Number: </strong>{" "}
          <a href={`tel:+91${item.number}`}>{item.number}</a> <br />
          <strong>Email: </strong> {item.email ? item.email : "No Email"}
          <br />
          <strong>Message: </strong>{" "}
          {item.message ? item.message : "No Message"}
          <br />
          <strong>Images: </strong>{" "}
          {item.imageUrl
            ? item.imageUrl.map((url, key) => (
                <li style={{ display: "inline" }}>
                  <a target="_blank" href={url}>
                    img-{key + 1}{" "}
                  </a>{" "}
                </li>
              ))
            : "No Images"}
        </li>
      ))}
    </ul>
  );

  const newsletterEmailForm = (
    <ul>
      {newsletterEmailFormData.map((item) => (
        <li key={item.name}>
          <strong>Email: </strong>
          {item.email}
        </li>
      ))}
    </ul>
  );

  useEffect(() => {
    getContactUsFormData();
    getCarEnquiryFormData();
    getCarServiceFormData();
    getCarDetailingFormData();
    getFinancingFormData();
    getInsuranceFormData();
    getSellYourCarFormData();
    getNewsletterEmailFormData();
  }, []);

  return (
    <>
      <section className={styles.section1}>
        <div className={styles.titleContainer}>
          <Title bg="ow"> dashboard</Title>
          <div>
            <button className={styles.button} onClick={getContactUsFormData}>
              Refresh
            </button>
            <button className={styles.button} onClick={logoutHandler}>
              Logout
            </button>
          </div>
        </div>{" "}
      </section>

      {/* Contact us form data */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(contactUsFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="contact us"
            content={contactUsForm}
          />
        )}
      </section>

      {/* car Enquiry form data */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(carEnquiryFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="Car Enquiry"
            content={carEnquiryForm}
          />
        )}
      </section>

      {/* {car service form data} */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(carServiceFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="Car service"
            content={carServiceForm}
          />
        )}
      </section>

      {/* car detailing form data */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(carDetailingFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="Car detailing"
            content={carDetailingForm}
          />
        )}
      </section>

      {/* {car financing form data} */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(financingFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="Car financing"
            content={financingForm}
          />
        )}
      </section>

      {/* {car insurance form data} */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(insuranceFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="Car insurance"
            content={InsuranceForm}
          />
        )}
      </section>

      {/* {car sell your car form data} */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(sellYourCarFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="Sell your car"
            content={sellYourCarForm}
          />
        )}
      </section>

      {/* {car newsletter email form data} */}

      <section className={styles.section3}>
        <div className={styles.buttonContainer}>
          <button
            className={styles.button}
            onClick={() => downloadCSV(newsletterEmailFormData)}
          >
            Export CSV
          </button>
        </div>
        {loading ? (
          <p>Loading Data...</p>
        ) : (
          <Accordion
            titleClassName={styles.accordionTitle}
            title="newsletter Email"
            content={newsletterEmailForm}
          />
        )}
      </section>

      <Footer />
    </>
  );
};

export default AdminPanel;
