import "../../utils/Colors/Colors.module.css";
import styles from "./CarouselCard.module.css";

const CarouselCard = ({ children, style }) => {
  return (
    <>
      <div
        className={styles.card}
        style={{
          ...style,
        }}
      >
        {children}
      </div>
    </>
  );
};
export default CarouselCard;
