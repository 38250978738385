import styles from "./Sell.module.css";
import { useNavigate } from "react-router-dom";
import Title from "../Title/Title";

const Sell = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className={styles.section1}>
        <Title bg="ow">Sell Your Car </Title>
        <div className={styles.description}>
          <p className={styles.subTitle}>
          With The Autocops, selling your car is now made simple –  {" "}
            <strong>Just 3 Minutes to an Offer! </strong>{" "}
          </p>
          <ol>
            <li>
              <strong>Instant Quotation</strong>: Receive immediate, competitive
              offers for your car.
            </li>
            <li>
              <strong>Doorstep Evaluation</strong> : Experience hassle-free assessments at the convenience of your location.
            </li>
            <li>
              <strong>Best Prices</strong>: Secure top-notch deals with our commitment to offering the best prices.
            </li>
            <li>
              <strong>Expert Advice </strong>: Get advice from our team of experts every step of the way when selling.
            </li>
            <li>
              <strong>	Trusted Buyers </strong>: sell your luxury car to a trusted dealership with a reputation for honesty and customer happiness
            </li>
            <li>
              <strong>	 Safe Transactions </strong>:  Feel confident with timely payments once terms are agreed upon.
            </li>
          
          </ol>
        </div>
        <div className={styles.description}>
          Join
          <span className={styles.span}>1000+ </span>Happy Customers!
        </div>
        <button onClick={() => navigate("/sellYourCar")} className={styles.btn}>
          Sell Your Car
        </button>
      </section>
    </>
  );
};
export default Sell;
