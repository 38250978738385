// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';
import ProductListingPage from './Pages/ProductListingPage/ProductListingPage';
import SellYourCar from './Pages/SellYourCar/SellYourCar';
import Services from './Pages/Services/Services';
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Pages/ContactUs/ContactUs';
import AdminPanel from './Pages/AdminPanel/AdminPanel';
import Login from './Pages/Login/Login';
import ProductDescriptionPage from './Pages/ProductDescriptionPage/ProductDescriptionPage';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Financing from './Pages/Financing/Financing';
import CalculateEmi from './Pages/CalculateEmi/CalculateEmi';
import Insurance from './Pages/Insurance/Insurance';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import CustomRequirements from './Pages/CustomRequirements/CustomRequirements';
import Upload from './Pages/Upload/Upload';
import RequiresAuth from './Components/RequiresAuth/RequiresAuth';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/stock" element={<ProductListingPage />} />
        <Route path="/stock/:id" element={<ProductDescriptionPage />} />
        <Route path="/sellYourCar" element={<SellYourCar />} />
        <Route path="/services" element={<Services />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route
          path="/admin"
          element={
            <RequiresAuth>
              <AdminPanel />
            </RequiresAuth>
          }
        />
        <Route
          path="/upload"
          element={
            <RequiresAuth>
              <Upload />
            </RequiresAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/financing" element={<Financing />} />
        <Route path="/calculateEmi" element={<CalculateEmi />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/requirements" element={<CustomRequirements />} />
      </Routes>
    </div>
  );
}

export default App;
