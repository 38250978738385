import React, { useEffect, useRef, useState } from "react";
import styles from "./Accordian.module.css";
import Title from "../Title/Title";

function Accordion(props) {
  const [active, setActive] = useState(false);
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  const { logoStyle } = props;
  const { bg } = props;
  const { titleStyle } = props;
  const { titleType } = props;
  const { titleClassName } = props;
  const { contentClassName } = props;

  useEffect(() => {
    // console.log("Height for ", props.title, ": ", height);
  }, [height]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? "0px" : `${content.current.scrollHeight}px`);
  }

  return (
    <div className={styles.accordion__section}>
      <div
        className={`${styles.accordian} ${active ? "active" : ""}`}
        onClick={toggleAccordion}
      >
        {titleType === "secondary" ? (
          <h3
            style={{ ...titleStyle }}
            bg={bg}
            logoStyle={{ ...logoStyle }}
            className={`${styles.accordion__title__secondary} ${titleClassName}`}
          >
            {" "}
            {props.title}
          </h3>
        ) : (
          <div className={styles.container}>
            {" "}
            <Title
              style={{ ...titleStyle }}
              bg={bg}
              logoStyle={{ ...logoStyle }}
              className={`${styles.accordion__title} ${titleClassName}`}
            >
              {props.title}
            </Title>
          </div>
        )}
        <div className={styles.toggleButton} style={{ fontSize: "28px" }}>
          {active ? "➖" : "➕"}
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className={`${styles.accordion__content}`}
      >
        <div
          className={`${styles.accordion__text} ${contentClassName}`}
          //   dangerouslySetInnerHTML={{ __html: props.content }}
        >
          {props.content}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
