import Footer from "../../Components/Footer/Footer";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import styles from "./Financing.module.css";
import Title from "../../Components/Title/Title";
import FaqSection from "../../Components/FaqSection/FaqSection";
import banner from "../../Images/financeImages/banner_1.jpg";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../Contexts/DataContext";
import Banner from "../../Components/Banner/Banner";
import Header from "../../Components/Navbar_2/Header";
import { Helmet } from "react-helmet";

const Financing = () => {
  const { state, dispatch, isValidPhoneNumber, sendMail } =
    useContext(DataContext);
  const faqs = [
    {
      question:
        "What credit score is generally required to qualify for The Autocops luxury car financing?",
      answer:
        "To qualify for The Autocops luxury car financing, a credit score of 750 and above is essential, ensuring favorable terms and conditions for potential buyers",
      open: false,
    },
    {
      question:
        "Do you finance older luxury vehicles or only newer models within a certain age range?",
      answer:
        "We finance luxury vehicles up to 12 years old, providing financial solutions for a diverse range of models within this specified age limit.",
      open: false,
    },
    {
      question:
        " Does the size of a down payment impact the financing rates and terms you can offer luxury car buyers?",
      answer:
        "No, the size of the down payment does not affect financing rates and terms, ensuring flexibility for luxury car buyers in their purchase journey.",
      open: false,
    },
    {
      question:
        " Do you provide financing for luxury vehicle upgrades and customizations in addition to just the purchase price?",
      answer:
        "While we finance vehicle upgrades like trade-ins, additional financing for customizations in pre-owned cars is not available.",
      open: false,
    },
    {
      question:
        "Can financing still be arranged even if purchasing from a private luxury vehicle seller rather than through your dealership inventory?",
      answer:
        "Certainly, we can arrange financing for luxury vehicles purchased outside our inventory, offering convenience for customers.",
      open: false,
    },
    {
      question:
        "With financing, can additional services like extended warranties be bundled into the total amount being financed for easier budgeting?",
      answer:
        "For pre-owned cars, insurance can be bundled into financing on a case-to-case basis. New car buyers can bundle products like insurance, accessories, warranty, and service plans through our Brand Financing Options.",
      open: false,
    },
  ];
  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    requirements: "",
  });

  let data, value;
  const getFormData = (event) => {
    data = event.target.name;
    value = event.target.value;
    setFormData({ ...formData, [data]: value });
  };

  const formSubmitHandler = async (event) => {
    const { name, number, email, requirements } = formData;

    event.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        number,
        email,
        requirements,
      }),
    };
    if (name.length > 0 && requirements.length > 0) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/financingData.json",
          options
        );

        // console.log(response);

        if (response) {
          alert(
            "Thankyou for your interest. our team will get back to you shortly."
          );
          sendMail(formData, "Car Financing");
        } else {
          alert("error");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setFormData({
        name: "",
        number: "",
        email: "",
        requirements: "",
      });
    } else {
      alert("Please fill the mandatory Details");
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
	      {/* <Navbar /> */}
      <Header />
      <Helmet>
        <title>Financial Services for Luxury Cars in Pune - The Autocops		</title>
        <meta name="description" content="Need finance services for luxury cars in Pune? We offer premium financing solutions for high-end vehicles. Explore our options today." />
        <meta property="og:title" content="Financial Services for Luxury Cars in Pune - The Autocops	" />
        <meta property="og:description" content="Need finance services for luxury cars in Pune? We offer premium financing solutions for high-end vehicles. Explore our options today." />
        <link rel="canonical" href="https://theautocops.com/financing"/>
        </Helmet>
      <Banner bannerStyles={{ paddingTop: "4rem", paddingBottom: "0rem" }} />
      <section className={styles.section}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={banner} alt="insurance" />
        </div>
      </section>{" "}
      <section className={`${styles.section1} ${styles.ow}`}>
        <div className={styles.container}>
          <Title bg="ow">SMART FINANCE FOR LUXURY CARS IN PUNE- THE AUTOCOPS WAY</Title>
        </div>
        <div className={styles.container}>
          <p>
          At The Autocops, we offer bespoke financing solutions tailored to the unique needs of luxury car enthusiasts. Our finance for used luxury cars in Pune will be customized based on your needs to help you drive home the car of your dreams. With our easy and transparent process, getting finance for luxury cars in Pune was never this easy. Trust The Autocops to turn your vision of luxury car ownership into a reality.
          </p>
        </div>{" "}
      </section>
      <section className={`${styles.section2} ${styles.w}`}>
        <div className={styles.container}>
          <Title>
          DRIVE YOUR DREAM: CUSTOMIZED FINANCIAL SERVICES FOR LUXURY CARS
          </Title>
        </div>
        <form>
          <h4>
          Fill in this simple form, and let our expert team design a financing plan tailored to your needs. Our financial services for luxury cars are specially designed to ensure hassle-free accessibility for the purchase of your luxury car. We're Here To Make Your Luxury Car Purchase Smooth And Accessible.
          </h4>
          <div>
            <input
              onChange={getFormData}
              value={formData.name}
              type="text"
              name="name"
              placeholder="Enter Customer Name*"
              required
            />
          </div>
          <div>
            <input
              onChange={getFormData}
              value={formData.number}
              type="tel"
              name="number"
              placeholder="Enter Customer Number*"
              required
            />
          </div>
          <div>
            <input
              onChange={getFormData}
              value={formData.email}
              type="email"
              name="email"
              placeholder="Enter Customer Email"
            />
          </div>
          <div>
            <input
              onChange={getFormData}
              value={formData.requirements}
              name="requirements"
              type="text"
              placeholder="Requirements*"
              required
            />
          </div>
          <button className={styles.button} onClick={formSubmitHandler}>
            Request Quote
          </button>
        </form>
      </section>
      <section className={`${styles.ow} ${styles.faqSection}`}>
        <FaqSection titleBg="ow" faqArray={faqs} />
      </section>
      <Footer />
    </>
  );
};
export default Financing;
