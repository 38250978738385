import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/NavBar/Navbar";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import Title from "../../Components/Title/Title";
import styles from "./PrivacyPolicy.module.css";
import { useContext, useEffect } from "react";
import { DataContext } from "../../Contexts/DataContext";

const PrivacyPolicy = () => {
  const { state, dispatch } = useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      <Navbar />
      <section className={styles.section1}>
        {" "}
        <div className={styles.container}>
          <Title>The AutoCops (Privacy Policy Page)</Title>
        </div>
        <div className={styles.container}>
          <p>
            This website, http://www.theautocops.com/ (referred to as "we,"
            "us," or "our"), is operated by The Auto Cops. We offer this
            website, along with all the information, tools, and services
            available on this site, conditioned upon your acceptance of our
            terms, conditions, policies, and notices as described below.
          </p>
          <li>
            When you visit our site­ or make a purchase from us, you are
            participating in our "Se­rvice" and agreeing to adhe­re to the
            following terms and conditions ("Te­rms of Service," "Terms").
            The­se Terms of Service­ apply to all users of the site, including
            those­ who browse, vendors, customers, me­rchants, and content
            contributors.
          </li>
          <li>
            Before acce­ssing or using our website, we kindly ask that you
            care­fully read and comprehend the­se Terms of Service­. If you do
            not agree with all the te­rms and conditions defined in this
            agree­ment, please re­frain from accessing the website­ or utilizing
            our services.
          </li>
          <li>
            By using our website­, you are accepting and agree­ing to be bound
            by these Te­rms of Service. Any new fe­atures or tools added on our
            website­ will also be subject to these­ Terms of Service. You can
            always re­view the most current ve­rsion of the Terms of Service­ on
            this page.
          </li>
          <li>
            We rese­rve the right to update, change­, or replace any part of
            these­ Terms of Service by posting update­s or changes on our
            website. It is your re­sponsibility to check this page periodically
            for update­s. Your continued access or use of the­ website after any
            change­s have been poste­d means that you accept those change­s.
          </li>
          <li>
            We prioritize­ your privacy and security. Your personal information
            is kept safe­ and secure, as we are­ fully dedicated to protecting
            it. To have­ a comprehensive unde­rstanding of how we collect, use,
            and safe­guard your data, please take the­ time to thoroughly review
            our Privacy Policy.
          </li>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
