import styles from "./WhatsappIcon.module.css";
import { IoLogoWhatsapp } from "react-icons/io";
import whatsapp from "../../Images/logos/whatsapp.png";
const WhatsappIcon = ({ style, carTitle, number, className }) => {
  // console.log(carTitle);
  // const phoneNumber = "+919999228484";
  const message = `Got reference from your Website. I want to enquire regarding ${carTitle}.`;

  const whatsappLink = `https://wa.me/+91${number}?text=${message}`;
  return (
    <>
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <img
          className={className || styles.image}
          style={{ ...style }}
          src={whatsapp}
          alt="whatsapp"
        />
      </a>
    </>
  );
};
export default WhatsappIcon;
