import styles from "./MeetTheTeam.module.css";
import React, { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaQuoteRight } from "react-icons/fa";
import data from "./data";

function MeetTheTeam({ carouselData }) {
  const [people, setPeople] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  // autoslide, clearInterval
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 3000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <section className={styles.section}>
      <div className={styles.titleContainer}>
        {/* <Title>Discover Our Team</Title> */}
      </div>
      <div className={styles.sectionCenter}>
        {people.map((person, personIndex) => {
          const { id, image, name, title, quote } = person;
          let position = styles.nextSlide;
          if (personIndex === index) {
            position = styles.activeSlide;
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === people.length - 1)
          ) {
            position = styles.lastSlide;
          }
          return (
            <div key={personIndex}>
              <article key={id} className={position}>
                <img src={image} alt={name} className={styles.personImg} />
                <h4>{name}</h4>
                <p className={styles.title}>{title}</p>
                <p className={styles.text}>{quote}</p>
                <FaQuoteRight className={styles.icon} />
              </article>
            </div>
          );
        })}
        <button
          className={`${styles.prev} ${styles.btn}`}
          onClick={() => setIndex(index - 1)}
        >
          <FiChevronLeft />
        </button>
        <button
          className={`${styles.next} ${styles.btn}`}
          onClick={() => setIndex(index + 1)}
        >
          <FiChevronRight />
        </button>
      </div>
    </section>
  );
}

export default MeetTheTeam;
