import styles from "./FaqSection.module.css";
import FAQ from "../FAQ/FAQ";
import { useState } from "react";
import Title from "../Title/Title";
import Accordian from "../Accordian/Accordian";

const FaqSection = ({ title, sectionBg, titleBg, faqArray }) => {
  const [faqs, setfaqs] = useState(faqArray);

  const toggleFAQ = (index) => {
    //console.log("toggled - nr:" + index);
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
        return faq;
      })
    );
  };

  const faq = (
    <div className={styles.faqContainer}>
      {faqs.map((faq, i) => {
        //console.log("i: " + i);
        return <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} key={i} />;
      })}{" "}
    </div>
  );
  return (
    <>
      <section className={`${styles.section} ${sectionBg}`}>
        <div className={styles.container}>
          <Accordian
            bg={titleBg}
            title="FAQ'S"
            titleType="secondary"
            content={faq}
            titleClassName={`${styles.accordianTitle} `}
          />
        </div>
      </section>
    </>
  );
};
export default FaqSection;
