import styles from "./FlipCard.module.css";
import { FaCar, FaMeteor } from "react-icons/fa";
import { BsFuelPump } from "react-icons/bs";
import WhatsappIcon from "../WhasappIcon/WhatsappIcon";
import CallIcon from "../CallIcon/CallIcon";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const FlipCard = ({
  id,
  children,
  title,
  odometerReading,
  fuelType,
  transmission,
  askingPrice,
  registration,
  thumbnailFront,
  imageStyleFront,
  classname,
  carData,
  stock,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [emi, setEmi] = useState("");
  // console.log(odometerReading);
  function replaceSpacesWithHyphens(input) {
    return input.replace(/ /g, '-');
  }
  const modifiedId = replaceSpacesWithHyphens(id);

  const navigate = useNavigate();

  // console.log(typeof Number(askingPrice));

  function calculateEMI(principal, annualInterestRate, tenureYears) {
    // Convert annual interest rate to monthly and percentage to decimal
    const monthlyInterestRate = annualInterestRate / 12 / 100;

    // Convert loan tenure to months
    const tenureMonths = tenureYears * 12;
    const updatedPrincipal = principal - 1000000;
    // console.log(updatedPrincipal);
    // Calculate EMI using the formula
    const calculatedEmi =
      (updatedPrincipal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, tenureMonths)) /
      (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

    setEmi(calculatedEmi.toFixed(0));
    // console.log(data.emi);

    return calculatedEmi.toFixed(2);
    // Round to two decimal places
  }
  // calculateEMI(1000000, 12, 5);
  // console.log(emi);

  useEffect(() => {
    calculateEMI(askingPrice, 11, 5);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`${styles.flipCard} ${classname}`}>
        <div className={styles.flipCardInner}>
          <div className={styles.flipCardFront}>
            <div>
              <img
                src={thumbnailFront}
                alt="Avatar"
                className={styles.navImg}
                style={{ ...imageStyleFront }}
              />
              <div className={styles.titleContainer}>
                <div className={styles.title}>
                  {stock === "true" || stock === true
                    ? `${title} (SOLD)`
                    : title}
                </div>
              </div>
            </div>

            <div className={styles.descriptionContainer}>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                  }}
                >
                  <FaCar />
                </span>
                <span style={{ marginRight: "14px" }}> {registration} </span>
              </div>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                  }}
                >
                  <BsFuelPump />
                </span>
                <span style={{ marginRight: "14px" }}>{fuelType} </span>
              </div>
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    marginRight: "8px",
                  }}
                >
                  <FaMeteor />
                </span>
                <span>{odometerReading} KMS </span>
              </div>
            </div>
            <div style={{ marginTop: "1.5rem" }}>
              <span style={{ marginRight: "4px" }}>&#8377;</span>
              <span
                style={{
                  fontSize: "18px",
                  textDecoration: "underline",
                }}
              >
                {askingPrice}
              </span>
              <span style={{ marginLeft: "10px" }}>
                {stock === "true" || stock === true
                  ? ""
                  : `EMI starts at ₹ ${emi}`}
              </span>
            </div>
          </div>
          <div className={styles.flipCardBack}>
            <div>
              <div className={styles.carTitle}>{title}</div>

              <div className={styles.description}>Type - {fuelType}</div>
              <div className={styles.description}>
                Transmission - {transmission}
              </div>
              <div className={styles.description}>
                Mileage counter - {odometerReading} kms
              </div>
              <div className={styles.description}>
                Asking Price - ₹ {askingPrice}
              </div>
            </div>
            <div>
              <div className={styles.description}>
                Contact us -{" "}
                <WhatsappIcon
                  number="7796660022"
                  carTitle={title}
                  style={{
                    borderRadius: "50%",
                    width: "32px",
                    marginBottom: "-0.5rem",
                    marginLeft: "1rem",
                  }}
                />
                <CallIcon
                  number="8390001122"
                  style={{
                    borderRadius: "50%",
                    width: "32px",
                    marginLeft: "10px",
                    marginBottom: "-0.5rem",
                  }}
                />
              </div>
            </div>
            <div>
              {stock === "true" || stock === true ? (
                <strong className={styles.showMore}>Sold</strong>
              ) : (
                <button
                  // style={{ marginLeft: "4rem" }}
                  onTouchEnd={(event) => {
                    navigate(`/stock/${modifiedId}`);
                  }}
                  onClick={() => navigate(`/stock/${modifiedId}`)}
                  className={styles.showMore}
                >
                  Show More
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FlipCard;
