import React, { useRef, useEffect, useState } from "react";
import styles from "./Banner.module.css";

const Banner = ({ bannerStyles, bannerClass }) => {
  const bannerTextRef = useRef(null);
  const [bannerWidth, setBannerWidth] = useState(0);

  useEffect(() => {
    if (bannerTextRef.current) {
      const width = bannerTextRef.current.offsetWidth;
      setBannerWidth(width * 2); // Set to twice the width of one instance
    }
  }, []);

  const bannerText = (
    <div ref={bannerTextRef} className={styles.bannerText}>
      <span>1000+</span>
      <p>Happy Sellers</p>
      <span>Best Price</span>
      <p>Guaranteed</p>
      <span>Swift</span>
      <p>Processing</p>
    </div>
  );
  return (
    <div
      style={{ ...bannerStyles }}
      className={`${styles.banner} ${bannerClass}`}
    >
      <div
        className={styles.bannerContent}
        style={{ width: `${bannerWidth}px` }}
      >
        {bannerText}
        {bannerText} {/* Duplicate the text */}
      </div>
    </div>
  );
};

export default Banner;
