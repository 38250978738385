// LoginPage.js
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Title from "../../Components/Title/Title";
import styles from "./Login.module.css"; // Assuming you have CSS modules set up
import Navbar from "../../Components/NavBar/Navbar";
import Footer from "../../Components/Footer/Footer";
import { LoginContext } from "../../Contexts/LoginContext";

const Login = () => {
  const { loginHandeler } = useContext(LoginContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    // You can add your authentication logic here, e.g., check username and password
    // For simplicity, we'll just log the values for now
    if (username !== "theautocops") {
      alert("invalid Username");
    }
    if (password !== "the_autocops@2021") {
      alert("Invalid Password");
    }
    if (username === "theautocops" && password === "the_autocops@2021") {
      // navigate("/admin");
      loginHandeler(true);
    }
  };

  return (
    <>
      <Navbar />
      <div className={styles.loginContainer}>
        <Title>The Autocops Admin Login</Title>
        <form onSubmit={handleLogin}>
          <div className={styles.formGroup}>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className={styles.button} type="submit">
            Login
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Login;
