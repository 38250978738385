import styles from "./Navbar.module.css";
import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import navlogo from "../../Images/logos/navlogo.jpg";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdown, setDropdown] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <nav className={styles.navbar}>
        {screenWidth < 768 && (
          <>
            <button className={styles.menuButton} onClick={toggleModal}>
              <AiOutlineMenu />
            </button>
            <div className={styles.logo}>
              <img
                onClick={() => navigate("/")}
                className={styles.logo}
                src={navlogo}
              />
            </div>
          </>
        )}
        {isModalOpen ? (
          <div
            className={`${styles.modalBackdrop} ${
              isModalOpen ? styles.showModalBackdrop : ""
            }`}
          >
            <div
              className={`${styles.modalContent} ${
                isModalOpen && styles.showModal
              }`}
            >
              <ul className={styles.menu}>
                <li
                  onClick={() => {
                    navigate("/");
                    setIsModalOpen(false);
                  }}
                >
                  Home
                </li>
                <li
                  onClick={() => {
                    navigate("/stock");
                    setIsModalOpen(false);
                  }}
                >
                  Stock
                </li>
                <li
                  onClick={() => {
                    navigate("/sellYourCar");
                    setIsModalOpen(false);
                  }}
                >
                  Sell
                </li>
                <li
                  onClick={() => {
                    navigate("/services");
                    setIsModalOpen(false);
                  }}
                >
                  Services
                </li>
                <li
                  onClick={() => {
                    navigate("/insurance");
                    setIsModalOpen(false);
                  }}
                >
                  Insurance
                </li>
                <li
                  onClick={() => {
                    navigate("/financing");
                    setIsModalOpen(false);
                  }}
                >
                  Financing
                </li>
                <li
                  onClick={() => {
                    navigate("/aboutUs");
                    setIsModalOpen(false);
                  }}
                >
                  About Us
                </li>
               
                <li
                  onClick={() => {
                    navigate("/contactUs");
                    setIsModalOpen(false);
                  }}
                >
                  Contact Us
                </li>
              </ul>
              <button className={styles.closeButton} onClick={toggleModal}>
                <AiOutlineClose />
              </button>
            </div>
          </div>
        ) : (
          screenWidth >= 768 && (
            <>
              <ul className={styles.menu}>
                <li>
                  <NavLink className={styles.link} to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to="/stock">
                    Stock
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to="/sellYourCar">
                    Sell
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to="/services">
                    Services
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink className={styles.link} to="/financing">
                    Financing
                  </NavLink>
                </li>{" "}
                <li>
                  <NavLink className={styles.link} to="/insurance">
                    Insurance
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to="/aboutUs">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to="/contactUs">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <div className={styles.logo}>
                <img
                  onClick={() => navigate("/")}
                  className={styles.logo}
                  src={navlogo}
                />
              </div>
            </>
          )
        )}{" "}
        {/* <Banner /> */}
      </nav>
      {/* <Banner /> */}
    </>
  );
};

export default Navbar;
