import styles from "./Footer.module.css";
import logo from "../../Images/logos/navlogo.jpg";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail, AiOutlineSend } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import { NavLink } from "react-router-dom";
import WhatsappIcon from "../WhasappIcon/WhatsappIcon";
import CallIcon from "../CallIcon/CallIcon";
import logoFacebook from "../../Images/logos/facebook.png";
import youTube from "../../Images/logos/youTube.png";
import logoInstagram from "../../Images/logos/insta.jpeg";
import logoTwitter from "../../Images/logos/twitter.png";
import { useContext, useState } from "react";
import { DataContext } from "../../Contexts/DataContext";

const Footer = () => {
  const [email, setEmail] = useState("");
  const { sendMail } = useContext(DataContext);

  const emailSubmitHandler = async (event) => {
    // const {email}=email

    event.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    };
    if (email.length > 0 && email.includes("@") && email.includes(".")) {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/newsletterEmail.json",
        options
      );

      // console.log(response);

      if (response) {
        alert("Email Registered");
        // sendMail(email, "Newsletter Email");
      } else {
        alert("error");
      }
      setEmail("");
    } else {
      alert("Please enter a valid Email.");
    }
  };
  return (
    <>
      <footer className={styles.footer}>
        {" "}
        <div
          style={{
            textAlign: "right",
            marginRight: "1rem",
            marginBottom: "-1rem",
          }}
        >
          <img className={styles.logo} alt="logo" src={logo} />
        </div>{" "}
        <div className={styles.flexContainer}>
          <div>
            <div className={styles.container}>
              <IoLocationOutline className={styles.icon} />
              Shop No.3, Corniche Tower, opposite ICICI Bank, Kalyani Nagar,
              Pune, Maharashtra 411006
            </div>
            <div className={styles.container}>
              <IoLocationOutline className={styles.icon} />
              Western Avenue K-Type, WESTERN AVENU, Shankar Kalat Nagar, Wakad,
              Pimpri-Chinchwad, Maharashtra 411057
            </div>

            <div className={styles.container}>
              <FiPhoneCall className={styles.icon} />{" "}
              <a href="tel:+917796660022">7796660022</a>
            </div>
            <div className={styles.container}>
              <FiPhoneCall className={styles.icon} />{" "}
              <a href="tel:+918390001122">8390001122</a>
            </div>
            <div className={styles.container}>
              <FiPhoneCall className={styles.icon} />{" "}
              <a href="tel:+917721859933">7721859933</a>
            </div>
            <div className={styles.container}>
              <AiOutlineMail className={styles.icon} />{" "}
              <a href="mailto:theautocops"> theautocops@gmail.com</a>
            </div>
          </div>
          <div className={styles.info}>
            <strong>Information</strong>
            <ul>
              <li>
                <NavLink className={styles.link} to="/requirements">
                  Custom Requirements
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/stock">
                  Our Stock
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/financing">
                  Financing
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/calculateEmi">
                  Emi Calculator
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/insurance">
                  Insurance
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/contactUs">
                  Contact Us
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/aboutUs">
                  About Us
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/privacypolicy">
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className={styles.link} to="/termsandconditions">
                  Terms and Conditions
                </NavLink>
              </li>
            </ul>
          </div>
          <div>
            <div className={styles.email}>
              <strong>Newsletter</strong>
            </div>
            <p className={styles.p}>
              Sign up for new stuff and special offers!
            </p>
            <p className={styles.p}>We promise not to spam you!</p>
            <div className={styles.inputContainer}>
              <input
                onChange={(event) => setEmail(event.target.value)}
                className={styles.input}
                type="email"
                value={email}
                placeholder="Enter your Email here"
              />
              <span onClick={emailSubmitHandler} className={styles.sendIcon}>
                🚀
              </span>
            </div>{" "}
            <a target="_blank" href="https://www.facebook.com/theautocops/">
              <img className={styles.followLogo} src={logoFacebook} />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/theautocops/?hl=en"
            >
              <img className={styles.followLogo} src={logoInstagram} />
            </a>
            <a
              target="_blank"
              href="https://youtube.com/@TheAutocops?si=l94oXXtrS_eWKvPn"
            >
              <img className={styles.followLogo} src={youTube} />
            </a>
            {/* <a target="_blank" href="">
              <img className={styles.followLogo} src={logoTwitter} />
            </a> */}
            <WhatsappIcon
              className={styles.followLogo}
              number="8390001122"
              carTitle="a car"
            />
            <CallIcon className={styles.followLogo} number="8390001122" />{" "}
            <div className={styles.rightsReserved}>
              <span className={styles.copyrightLogo}>©️</span> 2021 All Rights
              Reserved || The Autocops
            </div>
            <div className={styles.developer}>
              Designed and developed by{" "}
              <a
                target="_blank"
                href="https://amulya-chahal-portfolio.netlify.app/"
              >
                Amulya Chahal
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
