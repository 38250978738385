import FlipCard from "../FlipCard/FlipCard";
import styles from "./CurrentStock.module.css";
import { CarData } from "./Data";
// import image from "../../Images/Bg/slider.jpg";
import { Fade } from "react-awesome-reveal";
import Title from "../Title/Title";
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../Contexts/DataContext";

const CurrentStock = ({carName}) => {
  const { state } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const formattedCarName = carName ? carName.replace(/%20/g, '-') : '';
//  console.log(carName);
  // const getData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       "https://theautocops-5ac27-default-rtdb.firebaseio.com/productListingPageData.json"
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       const dataArray = Object.values(data).reverse();
  //       console.log(dataArray);
  //       setData(dataArray);
  //       // setData(Object.values(data));
  //       // Convert the object into an array
  //     } else {
  //       console.error("Failed to fetch data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  useEffect(() => {
    // getData();
  }, []);
  return (
    <>
      <Fade delay={100}>
        <section className={styles.section1}>
          {" "}
          <Title>EXPLORE OUR HANDPICKED USED LUXURY CAR COLLECTION IN PUNE</Title>
          <div>
            <p>
            Experience the epitome of sophistication with our handpicked used luxury cars in Pune. At AutoCops, we offer a diverse range of meticulously inspected pre-owned luxury vehicles. Each car in our inventory goes through a 200 point inspection to uphold top notch quality, Our stringent quality assurance process guarantees that every car meets the highest standards of excellence and dependability, making us the preferred destination for discerning buyers of used cars in Pune. Experience the fusion of luxury and quality at AutoCops, We stand as your ideal destination to buy a used luxury car in Pune.
            </p>{" "}
          </div>{" "}
        </section>
      </Fade>

      <section className={styles.section2}>
        <Fade delay={100}>
          <div>
            {state.stock.map((car) => (
              <FlipCard
                key={car.carTitle}
                classname={styles.card}
                id={car.carTitle}
                title={car.carTitle}
                thumbnailFront={car.imageUrl}
                fuelType={car.fuelType}
                odometerReading={car.odometerReading}
                askingPrice={car.askingPrice}
                transmission={car.transmission}
                registration={car.registration}
                stock={car.sold === "true" ? "old" : ""}
              />
            ))}
          </div>
          <div style={{ marginTop: "2rem" }}>
          {/* {carName && (
              <NavLink to={`/stock/${formattedCarName}`}>
                <button className={styles.button}>See more</button>
              </NavLink>
            )} */}
              <NavLink to="/stock">
              <button className={styles.button}>See more</button>{" "}
            </NavLink>{" "}
          </div>
        </Fade>
      </section>
    </>
  );
};
export default CurrentStock;
