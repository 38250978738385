import Navbar from "../../Components/NavBar/Navbar";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import styles from "./ProductListingPage.module.css";
import Title from "../../Components/Title/Title";
import FlipCard from "../../Components/FlipCard/FlipCard";
// import FlipCardSold from "../../Components/FlipCardSold/FlipCardSold";
// import image from "../../Images/Bg/slider.jpg";
import Footer from "../../Components/Footer/Footer";
import FAQ from "../../Components/FAQ/FAQ";
import FaqSection from "../../Components/FaqSection/FaqSection";
import SearchSection from "../../Components/SearchSection/SearchSection";
import Banner from "../../Components/Banner/Banner";
import Header from "../../Components/Navbar_2/Header";
import bg from "../../Images/plpImages/banner_1.jpg";
// import { CarData } from "../../Components/CurrentStock/Data";
import { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { DataContext } from "../../Contexts/DataContext";
import { Helmet } from "react-helmet";

const ProductListingPage = () => {
  const { state, dispatch } = useContext(DataContext);
  // const [filteredCarData, setFilteredCarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState({ make: "", bodyType: "" });

  const carData = [...state.stock, ...state.oldStock];
  // console.log(carData);
  useEffect(() => {
    if (carData.length > 0 && !state.filteredCarData.length > 0) {
      // setFilteredCarData(carData);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: carData });
      setLoading(false);
    } else if (state.filteredCarData.length > 0) {
      // console.log("from landing page", state.filteredCarData);
      dispatch({
        type: "SET_FILTERED_CAR_DATA",
        payload: state.filteredCarData,
      });
      setLoading(false);
    }
  }, [state.oldStock]);

  const submitHandler = (event) => {
    event.preventDefault();

    // console.log(search);

    if (search.make.length > 0 && search.bodyType.length > 0) {
      const SearchByTitle = carData.filter((car) => {
        const carTitleLowerCase = car.carTitle.toLowerCase();
        const makeLowerCase = search.make.toLowerCase();

        if (
          makeLowerCase === "jaguar land rover" &&
          (carTitleLowerCase.includes("jaguar") ||
            carTitleLowerCase.includes("range") ||
            carTitleLowerCase.includes("rover"))
        ) {
          return true;
        } else if (
          car.luxury === "no" &&
          search.make === "Other Brands (All other non luxury brands)"
        ) {
          return true;
        }
        // For other queries, filter the cars according to the selected make
        else if (carTitleLowerCase.includes(makeLowerCase)) {
          return true;
        }
      });
      // console.log(SearchByTitle);

      const searchByBodyType = SearchByTitle.filter((titleFilteredCar) => {
        if (
          titleFilteredCar.bodyType
            .toLowerCase()
            .includes(search.bodyType.toLowerCase())
        ) {
          return true;
        } else if (
          search.bodyType === "All"
          //&& titleFilteredCar.luxury === "yes"
        ) {
          return true;
        }
      });
      // setFilteredCarData(searchByBodyType);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: searchByBodyType });
    }

    if (search.make.length > 0 && !(search.bodyType.length > 0)) {
      const Search = carData.filter((car) => {
        const carTitleLowerCase = car.carTitle.toLowerCase();
        const makeLowerCase = search.make.toLowerCase();

        // If user selects 'jaguar land rover', include cars with titles containing 'jaguar', 'land', or 'rover'
        if (
          makeLowerCase === "jaguar land rover" &&
          (carTitleLowerCase.includes("jaguar") ||
            carTitleLowerCase.includes("range") ||
            carTitleLowerCase.includes("rover"))
        ) {
          return true;
        } else if (
          car.luxury === "no" &&
          search.make === "Other Brands (All other non luxury brands)"
        ) {
          return true;
        }
        // For other queries, filter the cars according to the selected make
        else if (carTitleLowerCase.includes(makeLowerCase)) {
          return true;
        }

        return false;
      });
      // console.log(Search);
      // setFilteredCarData(Search);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: Search });
    }

    if (!(search.make.length > 0) && search.bodyType.length > 0) {
      const Search = carData.filter((car) => {
        if (search.bodyType === "All") {
          return true;
        }
        if (
          car.bodyType.toLowerCase().includes(search.bodyType.toLowerCase())
        ) {
          return true;
        }
      });
      // console.log(Search);

      // setFilteredCarData(Search);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: Search });
    }
    // dispatch({ type: "SET_SEARCH", payload: filteredCarData });
  };

  // console.log(state.filteredCarData);
  const faqs = [
    {
      question:
        " Are there any extra fees or charges when buying a used car?",
      answer:
        "Our prices are very up-front, and we always do our best to minimize any additional fees or charges. If you are charged any registration or documentation fees, you will be completely transparent about them prior to your purchase being finalized. ",
      open: false,
    },
    {
      question:
        " How can I schedule a test drive?",
      answer:
        "You can easily book a test drive through our website or by contacting one of our showroom members directly. We will arrange a time with you at your nearest convenience to give you experience in your chosen used car.",
      open: false,
    },
    {
      question:
        " Can I trade in my car to The Autocops?",
      answer:
        "Absolutely! We have enticing exchange deals wherein you can get your current car exchanged for purchasing a used car from our inventory. Our team will evaluate your car and give you the correct market value for your trade-in.",
      open: false,
    },
    {
      question:
        " What types of used cars do you have in store?",
      answer:
        "In our inventory at The Autocops, we have a wide selection of used cars from major car companies like BMW, Mercedes-Benz, Audi, Toyota, and Land Cruiser. This includes sedans, SUVs, and off-road cars to suit every taste and purpose that our customers will require.",
      open: false,
    },
    {
      question:
        " Wherein are your showrooms located?",
      answer:
        "We have two conveniently located showrooms in Pune, one in Kalyani Nagar and another one in Wakad. These locations are specifically designed to help customers experience the best in car buying.",
      open: false,
    },
    
  ];

  return (
    <>
      <ScrollToTopOnMount />
      {/* <Navbar /> */}
      <Header />
      <Helmet>
        <title>Audi, BMW, Mercedes, Toyota & Land Rover Luxury Used Cars in Pune		</title>
        <meta name="description" content="Discover the best deals on used Audi, BMW, Mercedes, Toyota, and Land Rover cars in Pune, Nasik, and Thane. Find your perfect luxury car today!" />
        <meta property="og:title" content="Audi, BMW, Mercedes, Toyota & Land Rover Luxury Used Cars in Pune	" />
        <meta property="og:description" content="Discover the best deals on used Audi, BMW, Mercedes, Toyota, and Land Rover cars in Pune, Nasik, and Thane. Find your perfect luxury car today!" />
        <link rel="canonical" href="https://theautocops.com/stock"/>
        </Helmet>
      <Banner bannerStyles={{ paddingTop: "4rem", paddingBottom: "0rem" }} />

      <Fade delay={100}>
        <section className={styles.section1}>
          {" "}
          <img className={styles.image} src={bg} />
        </section>

        <section className={styles.searchSection}>
          <div className={styles.container}>
            <Title bg="ow">FIND YOUR RIDE: BMW, MERCEDES, AUDI, TOYOTO, LAND ROVER USED CARS</Title>
            <p>
            The Autocops is your destination for used luxury cars. See our handpicked selection of BMW used cars, Mercedes-Benz used cars, Audi used cars, Toyota used cars, and Land Rover used cars—painstakingly selected to afford you the best possible choice available.
              </p>
          </div>

          <div className={styles.container}></div>
          <div className={styles.accordionContainer}>
            <form>
              <h4>
                Look Out For Our Exclusive Collection of{" "}
                <strong>Premium Cars</strong>{" "}
              </h4>

              <div>
                <select
                  onChange={(event) =>
                    setSearch({ ...search, make: event.target.value })
                  }
                  className={styles.dropdown}
                >
                  <option>Make</option>
                  <option>Mercedes-Benz</option>
                  <option>BMW</option>
                  <option>Jaguar Land Rover</option>
                  <option>Audi</option>
                  <option>Porsche</option>
                  <option>Lexus</option>
                  <option>Maserati</option>

                  <option>Toyota Land Cruiser</option>

                  <option>Other Brands (All other non luxury brands)</option>
                </select>
              </div>
              <div>
                <select
                  onChange={(event) =>
                    setSearch({ ...search, bodyType: event.target.value })
                  }
                  className={styles.dropdown}
                >
                  <option>Body Type</option>
                  <option>Sedan</option>
                  <option>SUV</option>
                  <option>Hatchback</option>
                  <option>Convertible</option>
                  <option>Sports</option>
                  <option>Coupe</option>
                  <option>MUV</option>
                  <option>All</option>
                </select>
              </div>

              <button className={styles.button} onClick={submitHandler}>
                Search
              </button>
            </form>
          </div>
        </section>
        {/* <SearchSection
          carData={state.allStock}
          title="help us find you a ride"
        /> */}
        <section className={styles.section3}>
          <div className={styles.container}>
            <Title>SPOT YOUR DREAM WHEELS FROM OUR COLLECTION OF USED CARS</Title>
            <p className={styles.para}>
            From sleek sedans and versatile SUVs to rugged off-road cars, our collection spans across several styles to suit an array of preferences and lifestyles.<br/>
            Every pre-owned car in our collection has to pass a rigid inspection and certification process so that only the best can pass muster for our illustrious standards of excellence and reliability. Whether style, tech, or something practical is your wish, our collection of used cars has something extraordinary. 
            </p>
          </div>
        </section>
        <section className={styles.section4}>
          {" "}
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            state.filteredCarData.map((car, index) => (
              <FlipCard
                key={index}
                data={car}
                id={car.carTitle}
                classname={styles.card}
                title={car.carTitle}
                thumbnailFront={car.imageUrl}
                fuelType={car.fuelType}
                odometerReading={car.odometerReading}
                askingPrice={car.askingPrice}
                transmission={car.transmission}
                registration={car.registration}
                stock={car.sold}
              />
            ))
          )}
          {/* <div>
            {state.stock.map((car, index) => (
              <FlipCard
                key={index}
                data={car}
                id={car.carTitle}
                classname={styles.card}
                title={car.carTitle}
                thumbnailFront={car.imageUrl}
                fuelType={car.fuelType}
                odometerReading={car.odometerReading}
                askingPrice={car.askingPrice}
                transmission={car.transmission}
                registration={car.registration}
              />
            ))}
            <Fade style={{ display: "inline-block" }}>
              {state.oldStock.map((car, index) => (
                <FlipCard
                  key={index}
                  data={car}
                  id={car.carTitle}
                  classname={styles.card}
                  title={car.carTitle}
                  thumbnailFront={car.imageUrl}
                  fuelType={car.fuelType}
                  odometerReading={car.odometerReading}
                  askingPrice={car.askingPrice}
                  transmission={car.transmission}
                  registration={car.registration}
                  stock="old"
                />
              ))}
            </Fade>
          </div> */}
        </section>
        <section className={`${styles.faqSection} ${styles.ow}`}>
          <FaqSection faqArray={faqs} />
        </section>
      </Fade>
      <Footer />
    </>
  );
};
export default ProductListingPage;
