import React from "react";
import styles from "./FAQ.module.css";
import classNames from "classnames"; // Make sure to install this package if you haven't already.

function FAQ({ faq, index, toggleFAQ }) {
  // Using 'classnames' to combine them
  const faqClasses = classNames(styles.faq, {
    [styles.open]: faq.open, // This applies 'open' class conditionally
  });

  return (
    <div
      className={faqClasses} // updated usage
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className={styles.faqQuestion}>{faq.question}</div>
      <div className={styles.faqAnswer}>{faq.answer}</div>
    </div>
  );
}

export default FAQ;
