import FaqSection from "../../Components/FaqSection/FaqSection.jsx";
import Footer from "../../Components/Footer/Footer.jsx";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import Title from "../../Components/Title/Title.jsx";
import styles from "./Insurance.module.css";
import banner from "../../Images/insuranceImages/banner_1.jpg";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../Contexts/DataContext.jsx";
import "firebase/storage";
import "firebase/database";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Banner from "../../Components/Banner/Banner.jsx";
import Header from "../../Components/Navbar_2/Header.jsx";
import { Helmet } from "react-helmet";

const Insurance = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch, isValidPhoneNumber, sendMail } =
    useContext(DataContext);
  const faqs = [
    {
      question: " How do I choose the right coverage for my car?",
      answer:
        "Selecting the ideal insurance coverage involves prioritizing efficient claims processing and user-friendly documentation from reputable companies. Tailor your premium based on required coverages like Comprehensive, Zero Depreciation, Return-to-Invoice, Third Party, Key Cover, Hydrostatic Lock Cover, Consumables Cover, Natural Disasters, and Accessories Cover.",
      open: false,
    },
    {
      question:
        " Does your insurance coverage take into account the higher repair and replacement costs associated with luxury vehicles compared to standard models?",
      answer:
        "Absolutely. Reputable insurance companies acknowledge the elevated costs for luxury vehicle repairs. It's crucial to engage authorized service centers or workshops approved by the insurance company, with replacements for severely damaged parts often preferred over repairs.",
      open: false,
    },

    {
      question:
        " Do you offer any additional perks or benefits for The Autocops insurance customers, such as complimentary car washes or roadside assistance?",
      answer:
        "Certainly! Opt for The Autocops insurance to enjoy an industry-leading 80% discount on the OD premium. Additionally, benefit from a complimentary car wash, one free inspection, and free pick-up and drop services for any service-related needs.",
      open: false,
    },
    {
      question:
        " I have numerous custom upgrades and accessories for my luxury vehicle. How can I ensure proper coverage of their value?",
      answer:
        "Ensure coverage for custom upgrades and accessories by purchasing items with proper documentation, including a GST bill and warranty. An insurance company surveyor will inspect and incorporate these additions into your policy, subject to an additional, reasonable premium.",
      open: false,
    },
    {
      question:
        " What steps should I take after a car accident to claim insurance?",
      answer:
        "Initiate the insurance claim process by contacting the help line of the insurance company. Seek assistance from an authorized dealer for the necessary support, and they will guide you through the rest. In case of third-party damage, obtain an FIR from the police as per the situation.",
      open: false,
    },
    {
      question:
        "Will my high-performance luxury vehicle have coverage even if I take it to a closed-track facility for its intended use?",
      answer:
        "Insurance providers generally exclude coverage for racing and adventure activities. It's advisable to refrain from such activities to ensure your coverage remains intact.",
      open: false,
    },
    {
      question:
        "With your experience insuring luxury vehicles, do you have preferred body shops to properly repair these cars in the event of a claim?",
      answer:
        "Yes, all insurance companies collaborate with authorized dealers and workshops adhering to OEM standards. These preferred locations ensure proper repairs for luxury vehicles in the event of a claim.",
      open: false,
    },
  ];
  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    requirements: "",
    imageUrl: [],
  });
  const handleInsurancePageImageUpload = async (e) => {
    setIsLoading(true);
    const uploadPromises = [];
    const imageUrls = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `insurance/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((downloadURL) => {
          // console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL);
        });

      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("images uploaded successfully");
      // console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setFormData({
        ...formData,
        imageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };
  const imageInputForm = (
    <>
      <div>
        <input
          type="file"
          multiple
          accept="image/*"
          placeholder="Upload Images"
          onChange={handleInsurancePageImageUpload}
        />
      </div>
    </>
  );

  const handleInsurancePageInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const formSubmitHandler = async (e) => {
    const { name, number, email, requirements, imageUrl } = formData;
    e.preventDefault();
    if (
      name.length > 0 &&
      email.length > 0 &&
      requirements.length > 0 &&
      imageUrl.length > 0
    ) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/insuranceData.json",
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        // console.log(responseData);
        // This will log the unique key generated by Firebase for the new data.
        if (responseData) {
          alert(
            "Thankyou for your interest. our team will get back to you shortly."
          );
          sendMail(formData, "Car Insurance");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setFormData({
        name: "",
        number: "",
        email: "",
        requirements: "",
        imageUrl: [],
      });
    } else {
      alert("Please complete mandatory fields.");
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
	      <Header />
      <Helmet>
        <title>Luxury Car Insurance - Comprehensive Coverage | The Autocops		</title>
        <meta name="description" content="Protect your luxury car with our tailored insurance solutions. Get peace of mind for new and used premium vehicles. Customize your plan today!"/>
        <meta property="og:title" content="Luxury Car Insurance - Comprehensive Coverage | The Autocops	" />
        <meta property="og:description" content="Protect your luxury car with our tailored insurance solutions. Get peace of mind for new and used premium vehicles. Customize your plan today!" />
      <link rel="canonical" href="https://theautocops.com/insurance"></link>
        </Helmet>
      <Banner bannerStyles={{ paddingTop: "4rem", paddingBottom: "0rem" }} />
      <section className={styles.section}>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={banner} alt="insurance" />
        </div>
      </section>{" "}
      <section className={`${styles.section1} ${styles.ow}`}>
        <div className={styles.container}>
          <Title bg="ow">Insure Your Luxury Drive With The Autocops</Title>
        </div>
        <div className={styles.container}>
          <p>
            At The Autocops, we specialize in providing comprehensive insurance
            solutions for luxury used cars, as well as new cars. Our policies
            are designed to offer maximum protection and convenience, ensuring
            your peace of mind. Explore our tailored insurance options to
            safeguard your premium vehicle.
          </p>
        </div>
      </section>
      {/* <section className={styles.section2}>
        <div className={styles.container}>
          <Title>The AutoCops make the car insurance process hassle-free</Title>
        </div>
      </section> */}
      <section className={`${styles.section3} ${styles.w}`}>
        <div className={styles.container}>
          <Title>
            Discover Effortless and Reliable Car Insurance with The Autocops
          </Title>
        </div>
        <form>
          {" "}
          <h4>
            Simply fill out this form, and our dedicated team will promptly
            respond with a customized insurance solution tailored to your car's
            needs. 💳
          </h4>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleInsurancePageInputChange(e, "name")}
              value={formData.name}
              type="text"
              name="name"
              placeholder="Enter Customer Name*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleInsurancePageInputChange(e, "number")}
              value={formData.number}
              type="tel"
              name="number"
              placeholder="Enter Customer Number*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleInsurancePageInputChange(e, "email")}
              value={formData.email}
              type="email"
              name="email"
              placeholder="Enter Customer Email*"
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) =>
                handleInsurancePageInputChange(e, "requirements")
              }
              value={formData.requirements}
              name="requirements"
              type="text"
              placeholder="Requirements*"
              required
            />
          </div>{" "}
          <div className={styles.inputContainer}>
            <h3>Upload Previous Insurance and Rc Images*:</h3>
            <input
              type="file"
              multiple
              accept="image/*"
              placeholder="Upload Images"
              onChange={handleInsurancePageImageUpload}
            />
          </div>{" "}
          <div className={styles.inputContainer}>
            <button
              onClick={formSubmitHandler}
              disabled={isLoading}
              className={styles.button}
            >
              {isLoading ? "Uploading..." : "Request Quote"}
            </button>
          </div>
        </form>
      </section>
      <section className={`${styles.ow} ${styles.faqSection}`}>
        <FaqSection titleBg="ow" faqArray={faqs} />
      </section>
      <Footer />
    </>
  );
};
export default Insurance;
