import Footer from "../../Components/Footer/Footer";
import styles from "./Services.module.css";
import Title from "../../Components/Title/Title";
import image from "../../Images/serviceImages/banner_2.jpg";
import detailingImage from "../../Images/serviceImages/Detailing.jpg";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import { NavLink } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import "firebase/storage";
import "firebase/database";
import { DataContext } from "../../Contexts/DataContext";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Banner from "../../Components/Banner/Banner";
import Header from "../../Components/Navbar_2/Header";
import { Helmet } from "react-helmet";

const Services = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { state, dispatch, isValidPhoneNumber, sendMail } =
    useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  const [serviceFormData, setServiceFormData] = useState({
    name: "",
    number: "",
    email: "",
    carModel: "",
    requirements: "",
    imageUrl: [],
  });

  const [detailingFormData, setDetailingFormData] = useState({
    name: "",
    number: "",
    email: "",
    carModel: "",
    requirements: "",
    imageUrl: [],
  });

  const handleServiceImageUpload = async (e) => {
    setIsLoading(true);
    const uploadPromises = [];
    const imageUrls = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `carService/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((downloadURL) => {
          console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL);
        });

      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("images uploaded successfully");
      console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setServiceFormData({
        ...serviceFormData,
        imageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDetailingImageUpload = async (e) => {
    setIsLoading(true);
    const uploadPromises = [];
    const imageUrls = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `carDetailing/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((downloadURL) => {
          console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL);
        });

      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("images uploaded successfully");
      console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setDetailingFormData({
        ...detailingFormData,
        imageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleServiceInputChange = (e, field) => {
    setServiceFormData({
      ...serviceFormData,
      [field]: e.target.value,
    });
  };

  const handleDetailingInputChange = (e, field) => {
    setDetailingFormData({
      ...detailingFormData,
      [field]: e.target.value,
    });
  };

  const handleDropdownChange = (event) => {
    console.log(event.target.value);
    setDetailingFormData((prevFormData) => {
      return {
        ...prevFormData,
        requirements: event.target.value,
      };
    });
    console.log(detailingFormData);
  };
  const serviceFormSubmitHandler = async (e) => {
    const { name, number, email, carModel, requirements, imageUrl } =
      serviceFormData;
    e.preventDefault();
    if (
      name.length > 0 &&
      email.length > 0 &&
      carModel.length > 0 &&
      requirements.length > 0
    ) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/carServiceData.json",
          {
            method: "POST",
            body: JSON.stringify(serviceFormData),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData); // This will log the unique key generated by Firebase for the new data.
        if (responseData) {
          alert(
            "Thankyou for your interest. our team will get back to you shortly."
          );
          sendMail(serviceFormData, "Car Service");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setServiceFormData({
        name: "",
        number: "",
        email: "",
        carModel: "",
        requirements: "",
        imageUrl: [],
      });
    } else {
      alert("Please complete mandatory fields.");
    }
  };

  const detailingFormSubmitHandler = async (e) => {
    const { name, number, email, carModel, requirements, imageUrl } =
      detailingFormData;
    e.preventDefault();
    if (
      name.length > 0 &&
      email.length > 0 &&
      carModel.length > 0 &&
      requirements.length > 0
    ) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/carDetailingData.json",
          {
            method: "POST",
            body: JSON.stringify(detailingFormData),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        console.log(responseData); // This will log the unique key generated by Firebase for the new data.
        if (responseData) {
          alert(
            "Thankyou for your interest. our team will get back to you shortly."
          );
          sendMail(detailingFormData, "Car Detailing");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setDetailingFormData({
        name: "",
        number: "",
        email: "",
        carModel: "",
        requirements: "",
        imageUrl: [],
      });
    } else {
      alert("Please complete mandatory fields.");
    }
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);

    if (section) {
      // Calculate the section's position relative to the viewport
      const offset = section.getBoundingClientRect().top;

      // Scroll to the section with smooth behavior
      window.scroll({
        top: offset + window.scrollY,
        behavior: "smooth",
      });
    }
  }

  return (
    <>
      <ScrollToTopOnMount />
	 
      {/* <Navbar /> */}
      <Header />
      <Helmet>
        <title>Luxury Cars: Sales, Financing, Insurance & Services	</title>
        <meta name="description" content="Find new and pre-owned luxury cars with personalized financing, comprehensive insurance, and exclusive after-sales support. Elevate your driving with our detailed care services." />
        <meta property="og:title" content="Luxury Cars: Sales, Financing, Insurance & Services" />
        <meta property="og:description" content="Find new and pre-owned luxury cars with personalized financing, comprehensive insurance, and exclusive after-sales support. Elevate your driving with our detailed care services." />
        <link rel="canonical" href="https://theautocops.com/services"/>
        </Helmet>
      <Banner bannerStyles={{ paddingTop: "4rem", paddingBottom: "0rem" }} />

      <section className={`${styles.section1} `}>
        <img alt="services" className={styles.image} src={image} />
      </section>
      <section className={`${styles.section2} ${styles.ow}`}>
        <div className={styles.container}>
          <Title bg="ow">EXCEPTIONAL SERVICES AND FACILITIES</Title>
        </div>
        <div>
          <ul>
            <li>
              <NavLink className={styles.link} to="/stock">
                {" "}
                <strong>New and Pre-owned Luxury Car Sales: </strong>
              </NavLink>
              Elevate your driving experience with our collection of new and pre-owned luxury cars.
            </li>
            <li>
              <NavLink className={styles.link} to="/sellYourCar">
                {" "}
                <strong>Purchase of New and Pre-owned Luxury Cars: </strong>
              </NavLink>
              Explore a seamless process for selling your luxury car to us or upgrading with a purchase.
            </li>

            <li>
              <NavLink className={styles.link} to="/financing">
                <strong>Tailored Car Finance Solutions: </strong>
              </NavLink>
              Unlock personalized financing options to make your dream luxury car more accessible.
            </li>
            <li>
              <NavLink className={styles.link} to="/insurance">
                <strong>Comprehensive Car Insurance: </strong>
              </NavLink>
              Safeguard your investment with our comprehensive and tailored car insurance solutions.
            </li>
            <li>
              <a
                onClick={() => scrollToSection("contact-support")}
                className={styles.link}
              >
                <strong>Dedicated After-Sales Support: </strong>
              </a>
              Experience unparalleled post-purchase service and support to keep your car running smoothly.
            </li>
            <li>
              <a
                onClick={() => scrollToSection("detailing-service")}
                className={styles.link}
              >
                <strong>Precision Car Detailing: </strong>
              </a>
              Restore and enhance the beauty of your luxury car with our meticulous car detailing services.
            </li>
            <li>
              <NavLink className={styles.link} to="/">
                <strong>Professional Services in Denting and Painting: </strong>
              </NavLink>
              Our experts who will give you the denting and painting services  to restore the aesthetic appeal of your car. Our denting and painting services will  bring back your vehicle's pristine condition. 

            </li>
            
            <li>
              <a
                onClick={() => scrollToSection("detailing-service")}
                className={styles.link}
              >
                <strong>Ceramic Coating Services: </strong>
              </a>
              Shield and enhance the exterior of your car with our ceramic coating services for long-lasting radiance and protection from hazardous elements.            </li>
           
            <li>
              <a
                onClick={() => scrollToSection("detailing-service")}
                className={styles.link}
              >
                <strong>Paint Protection Film Services: </strong>
              </a>
              The best paint protection film services for shielding the car's paint so that it remains more safe from scratches and other natural elements.
            </li>
           
          </ul>
        </div>
      </section>

      <section className={styles.section4} id="contact-support">
        <div className={styles.container}>
          <Title>REACH OUT NOW FOR TOP-NOTCH VEHICLE CARE</Title>
        </div>
        <form>
          {" "}
          <h4>
            Simply complete the form, and our team will craft a personalized
            service plan just for you. 🤝
          </h4>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServiceInputChange(e, "name")}
              value={serviceFormData.name}
              type="text"
              name="name"
              placeholder="Enter Customer Name*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServiceInputChange(e, "number")}
              value={serviceFormData.number}
              type="tel"
              name="number"
              placeholder="Enter Customer Number*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServiceInputChange(e, "email")}
              value={serviceFormData.email}
              type="email"
              name="email"
              placeholder="Enter Customer Email*"
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServiceInputChange(e, "carModel")}
              value={serviceFormData.carModel}
              type="text"
              name="carModel"
              placeholder="Enter Car Model*"
            />
          </div>
          <div className={styles.inputContainer}>
            {/* <select className={styles.dropdown} onChange={handleDropdownChange}>
              <option>
                Epic Shine Turbo Wash- (For your reference- Washing service)
              </option>
              <option>
                Silk Shimmer Wax Works (For your reference- Wash & wax service)
              </option>
              <option>
                Eternal Glow Shine & Armor (For your reference- Wash, wax +
                polish service)
              </option>
              <option>
                Chrome Shine Fusion Finish (For your reference- Wash, wax,
                polish + cutting service)
              </option>
              <option>
                Eco Fresh Interior Spa (Interior deep cleaning service)
              </option>
              <option>Luxe Guard Ceramic Armor (Ceramic Service)</option>
              <option>Luxe Guard PPF Shield (PPF Service)</option>
              <option>others (Enter Custom Requirements here)</option>
            </select> */}
          </div>{" "}
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServiceInputChange(e, "requirements")}
              value={serviceFormData.requirements}
              name="requirements"
              type="text"
              placeholder="Requirements*"
              required
            />
          </div>{" "}
          <div className={styles.inputContainer}>
            <h3>Upload Car Images if any:</h3>
            <input
              type="file"
              multiple
              accept="image/*"
              placeholder="Upload Images"
              onChange={handleServiceImageUpload}
            />
          </div>{" "}
          <div className={styles.inputContainer}>
            <button
              onClick={serviceFormSubmitHandler}
              disabled={isLoading}
              className={styles.button}
            >
              {isLoading ? "Uploading..." : "Request Quote"}
            </button>
          </div>
        </form>
      </section>

      <section
        id="detailing-service"
        className={`${styles.section6} ${styles.ow}`}
      >
        <div className={styles.container}>
          <Title bg="ow" className={styles.title}>
            DEDICATED DETAILING, CERAMIC & PPF CENTRE
          </Title>
        </div>
        <div className={styles.imageContainer}>
          <img src={detailingImage} className={styles.cardImage} />{" "}
        </div>
        <div className={styles.container}>
          <p>
          We are dedicated to detailing, ceramic coating services, and paint protection film services. Ceramic coatings avail long-lasting protection from the elements, having a lasting glossy finish. PPF services by us help your car look pristine from chips and scratches. We also offer professional services for denting and painting to empower your ride back into its beauty. With The Autocops, you can count on getting protection for all your car care needs.
          </p>
        </div>
        <div className={styles.container}>
          <ul>
            <li>
              <strong>Epic Shine Turbo Wash</strong>
              <p>
              Rapid yet thorough, our Epic Shine Turbo Wash promises more than a quick clean. It's an instant makeover, giving your car the sparkle it deserves without the time-consuming hassle.
              </p>
            </li>
            <li>
              <strong>Silk Shimmer Wax Works</strong>
              <p>
              Embrace the luxurious touch with Silk Shimmer Wax Works. It's not just about shine; it's about a smooth, silky finish that adds that extra touch of elegance to your cherished ride.
              </p>
            </li>
            <li>
              <strong>Eternal Glow Shine & Armor</strong>
              <p>
              More than a polish – it's a commitment to lasting brilliance and protection. Eternal Glow Shine & Armor ensures your car remains a head-turner, rain or shine, with an added layer of defence. 
              </p>
            </li>
            <li>
              <strong>Ceramic Coating</strong>
              <p>Unveil the advantages and benefits of ceramic coating:</p>
              <ul>
                <li>Protection from Harmful UV Rays</li>
                <li>Protection from Chemical Stains</li>
                <li>Hydrophobic Nature, Ease of Cleaning</li>
                <li>Candy-Like Gloss</li>
                <li>Protection from All Types of Scratches and Swirl Marks</li>
                <li>Protection from Water Spotting</li>
                <li>Stops the Need for Car Wash</li>
              </ul>
              <p>
                Plus, produce an enhanced finish that adds an extra layer of
                gloss to your vehicle.
              </p>
            </li>
            <li>
              <strong>Chrome Shine Fusion Finish</strong>
              <p>
                Precision meets dazzle with Chrome Shine Fusion Finish. Elevate
                your car's sheen with a meticulous polish that goes beyond the
                surface, making your vehicle stand out effortlessly.
              </p>
            </li>
            <li>
              <strong>PPF Shield</strong>
              <p>Shield your beauty with Luxe Guard PPF Shield:</p>
              <ul>
                <li>Produces an enhanced finish</li>
                <li>Superior protection against corrosion</li>
                <li>Instant healing of minor scratches</li>
                <li>High-temperature resistance</li>
                <li>Ease of repairability</li>
                <li>Preserves the resale value</li>
              </ul>
            </li>
            <li>
              <strong>Eco Fresh Interior Spa</strong>
              <p>
                Revitalize your ride from the inside out with Eco Fresh Interior
                Spa. It's not just a cleaning; it's a rejuvenation, ensuring
                your car's interiors are as fresh and inviting as the first time
                you stepped in.
              </p>
            </li>
            <li>
              <strong>Epic Shine Turbo Wash</strong>
              <p>
                Rapid yet thorough, our Epic Shine Turbo Wash promises more than
                a quick clean. It's an instant makeover, giving your car the
                sparkle it deserves without the time-consuming hassle.
              </p>
              <ul>
                <li>Enhance the appearance of your vehicle</li>
                <li>Provide protection against environmental damage</li>
                <li>Improve resale value</li>
                <li>Promote interior health and comfort</li>
                <li>Preserve interior components</li>
                <li>Save you time and effort</li>
              </ul>
            </li>
            <li>
              <strong>Luxe Guard Ceramic Armor</strong>
              <p>
                Give your car the luxury it deserves with Luxe Guard Ceramic
                Armor. Beyond aesthetics, it's a long-lasting ceramic coating
                providing an exquisite shine, making your car feel like royalty
                on the road.
              </p>
            </li>
            <li>
              <strong>Luxe Guard PPF Shield</strong>
              <p>
                Defend your car's beauty against the elements with Luxe Guard
                PPF Shield. It's not just protection; it's a shield ensuring
                your car's exterior remains flawless, no matter what the road
                throws at it.
              </p>
              <p>
                Discover a new standard in automotive care at The Autocops. Each
                service is a commitment to perfection, ensuring your luxury car
                receives the care it truly deserves. Elevate your car care
                experience with us.
              </p>
            </li>
            <li>
              <Title bg="ow">
                Drive Safe, Drive Strong – Autocops, Your Car's Shield
              </Title>
              <p>
                Your car’s safety and durability matter to us, our services go
                beyond aesthetics. We believe in enhancing your car's
                resilience, ensuring it not only looks great but stays safe and
                durable for all your journeys.
              </p>
              <ul>
                <li>
                  <strong>Safe & Durable Care: </strong>Trust in our services
                  not just for aesthetics but for enhanced safety and
                  durability. We prioritize your car's longevity, making every
                  drive a secure and enduring experience.
                </li>
                <li>
                  <strong>Precision for Longevity: </strong>Our meticulous
                  detailing isn't just about the looks; it's about ensuring your
                  car maintains its prime condition for the long run. Experience
                  The Autocops' precision for enduring quality.
                </li>
              </ul>
              <p>
                Ready to elevate your ride? Experience exceptional car care at
                The Autocops. Because every ride deserves to be exceptional.
                Contact us now for a tailored service plan that suits your car's
                needs!
              </p>
            </li>
          </ul>
        </div>
        <form>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleDetailingInputChange(e, "name")}
              value={detailingFormData.name}
              type="text"
              name="name"
              placeholder="Enter Customer Name*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleDetailingInputChange(e, "number")}
              value={detailingFormData.number}
              type="tel"
              name="number"
              placeholder="Enter Customer Number*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleDetailingInputChange(e, "email")}
              value={detailingFormData.email}
              type="email"
              name="email"
              placeholder="Enter Customer Email*"
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleDetailingInputChange(e, "carModel")}
              value={detailingFormData.carModel}
              type="text"
              name="carModel"
              placeholder="Enter Car Model*"
            />
          </div>
          <div className={styles.inputContainer}>
            <select className={styles.dropdown} onChange={handleDropdownChange}>
              <option>
                Epic Shine Turbo Wash- (For your reference- Washing service)
              </option>
              <option>
                Silk Shimmer Wax Works (For your reference- Wash & wax service)
              </option>
              <option>
                Eternal Glow Shine & Armor (For your reference- Wash, wax +
                polish service)
              </option>
              <option>
                Chrome Shine Fusion Finish (For your reference- Wash, wax,
                polish + cutting service)
              </option>
              <option>
                Eco Fresh Interior Spa (Interior deep cleaning service)
              </option>
              <option>Luxe Guard Ceramic Armor (Ceramic Service)</option>
              <option>Luxe Guard PPF Shield (PPF Service)</option>
              <option>others (Enter Custom Requirements here)</option>
            </select>
          </div>{" "}
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleDetailingInputChange(e, "requirements")}
              value={detailingFormData.requirements}
              name="requirements"
              type="text"
              placeholder="Requirements*"
              required
            />
          </div>{" "}
          <div className={styles.inputContainer}>
            <h3>Upload Car Images if any:</h3>
            <input
              type="file"
              multiple
              accept="image/*"
              placeholder="Upload Images"
              onChange={handleDetailingImageUpload}
            />
          </div>{" "}
          <div className={styles.inputContainer}>
            <button
              onClick={detailingFormSubmitHandler}
              disabled={isLoading}
              className={styles.button}
            >
              {isLoading ? "Uploading..." : "Request Quote"}
            </button>
          </div>
        </form>
      </section>

      <section className={`${styles.section3} ${styles.w}`}>
        {/* <div className={styles.container}>
          <Title>
            Looking for top-notch car service? Contact us now for exceptional
            care for your vehicle!
          </Title>
        </div> */}
        <form>
          {" "}
          {/* <h4>
            Just Fill in the form and our team will get back with a tailored car
            service solution for you. 🤝
          </h4>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServicePageInputChange(e, "name")}
              value={formData.name}
              type="text"
              name="name"
              placeholder="Enter Customer Name*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServicePageInputChange(e, "number")}
              value={formData.number}
              type="number"
              name="number"
              placeholder="Enter Customer Number*"
              required
            />
          </div>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => handleServicePageInputChange(e, "email")}
              value={formData.email}
              type="email"
              name="email"
              placeholder="Enter Customer Email*"
            />
          </div> */}
        </form>
      </section>
      <Footer />
    </>
  );
};
export default Services;
