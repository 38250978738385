import styles from "./LandingPage.module.css";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import SearchSection from "../../Components/SearchSection/SearchSection";
import CurrentStock from "../../Components/CurrentStock/CurrentStock";
import WelcomeSection from "../../Components/WelcomeSection/WelcomeSection";
import EmiCalculator from "../../Components/EmiCalculator/EmiCalculator";
import Sell from "../../Components/Sell/Sell";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Footer from "../../Components/Footer/Footer";
import Banner from "../../Components/Banner/Banner";
import { Fade } from "react-awesome-reveal";
import FaqSection from "../../Components/FaqSection/FaqSection";
import Header from "../../Components/Navbar_2/Header";
import banner_small from "../../Images/landingPageImages/banner_2.jpg";
import { useContext, useEffect } from "react";
import { DataContext } from "../../Contexts/DataContext";
import { Helmet } from "react-helmet";



const LandingPage = () => {
  const { state, dispatch } = useContext(DataContext);

  const faqs = [
    {
      question:
        " Is it  good to buy used luxury cars from The Autocops?",
      answer:
        "Yes, when you buy through The Autocops, rest assured that you are getting a thoroughly   inspected, high-quality vehicle with excellent customer service to give it to the company. ",
      open: false,
    },
    {
      question:
        " 	What are the best used luxury cars available at The Autocops?",
      answer:
        "In our inventory, you will find only top models from iconic brands such as Mercedes-Benz, BMW, Land Rover, Audi, Porsche, etc. Each car is hand-picked and engineered with stringent quality control.",
      open: false,
    },
    {
      question:
        " Should you buy a used luxury car in India?",
      answer:
        " Yes, it may be a financially acute decision to buy a used luxury car in India. With The Autocops, one can be assured that a car shall meet the finest standards for quality and reliability.",
      open: false,
    },
    {
      question:
        "	What are the benefits of buying from used luxury car dealers in Pune like The Autocops?  ",
      answer:
        "The Autocops offers unbeatable service, a wide range of premium cars, unbeatable prices, and detailed inspection make The Autocops the top choice to buy used luxury cars in Pune.",
      open: false,
    },
    {
      question:
        " 	Where can I find used luxury cars near me? ",
      answer:
        "Head to The Autocops, Pune, and view our collection. Our dealership is conveniently located, making it easier to hunt for used luxury cars of your choice.",
      open: false,
    },
    {
      question:
        " 	Which used luxury car should I buy?",
      answer:
        "Our team of experts will guide you through your preferences and needs. Whether performance or comfort or style, we have a luxury car that does justice to your requirements.",
      open: false,
    },
    {
      question:
        " 	Do you have any affordable used luxury cars?",
      answer:
        " Yes, The Autocops comes with a wide array of luxury cars at competitive prices—to buy quality without costing an arm and a leg.",
      open: false,
    },
    {
      question:
        " 		Should you buy used luxury cars in India?",
      answer:
        " You have a hassle-free experience buying your preferred model from us and having it inspected.You'll also have hassle-free financing options to close the deal.",
      open: false,
    },
   
    
  ];
  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);


 

  return (
    <>

     

      <ScrollToTopOnMount />
      <div className={styles.container}>
        <Header />

        <Helmet>
        <title>Buy Affordable Used Luxury Cars Dealers in Pune - The Autocops	</title>
        <meta name="description" content="Find affordable used luxury cars near you in Mumbai and Pune. Our best used luxury car dealers offer the best deals on buying used luxury cars today!" />
        <meta property="og:title" content="Buy Affordable Used Luxury Cars Dealers in Pune - The Autocops" />
        <meta property="og:description" content="Find affordable used luxury cars near you in Mumbai and Pune. Our best used luxury car dealers offer the best deals on buying used luxury cars today!" />
        <link rel="canonical" href="https://theautocops.com/"/>
        </Helmet>
        <Banner
          bannerStyles={{ paddingTop: "4rem" }}
          bannerClass={styles.banner}
        />
        <Fade delay={100}>
          <section>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={banner_small} />
            </div>
          </section>
          <SearchSection
            title="FIND YOUR USED LUXURY CAR WITH THE AUTOCOPS "
            carData={state.allStock}
          />
          <CurrentStock />
          <WelcomeSection />
          <EmiCalculator />
          <Sell />
          <Testimonials />
          <section className={`${styles.ow} ${styles.faqSection}`}>
            <FaqSection faqArray={faqs} />
          </section>
          <Footer />
        </Fade>
      </div>
    </>
  );
};
export default LandingPage;
