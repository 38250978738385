import styles from "./WelcomeSection.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import Title from "../Title/Title";

const WelcomeSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className={styles.section}>
        <Title bg="ow">The Autocops Story</Title>
        <div>
          <p>
          The Autocops is Pune's renowned hub for buying and selling used luxury cars. We're not just about cars; we're about crafting unforgettable experiences.
          </p>
          <p>
          Our team, seasoned with expertise from iconic luxury brands like Mercedes-Benz, BMW, Land Rover, and Audi, is dedicated to guiding you to your perfect match. Experience unparalleled service where your delight is our benchmark.
          </p>
          <p>
          Come, join our family and let us steer you towards the car you've always dreamed of.
          </p>
          <button
            onClick={() => navigate("/aboutUs")}
            className={styles.description}
          >
            {" "}
            Read More{" "}
          </button>
        </div>
      </section>
    </>
  );
};
export default WelcomeSection;
