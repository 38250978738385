import styles from "./Title.module.css";
import wheel_ow from "../../Images/logos/wheel_9_ow_colored.png";
import wheel_w from "../../Images/logos/wheel_9_colored.png";
import { Helmet } from "react-helmet";

const Title = ({ children, style, logoStyle, className, span, bg }) => {
  return (
    <>
      <div style={{ display: "flex" }}>
        <img
          className={styles.logo}
          src={bg === "ow" ? wheel_ow : wheel_w}
          style={{
            ...logoStyle,
          }}
        />
        {/* <Helmet> */}
        <h1
          style={{ ...style, display: "inline-block" }}
          className={`${styles.title} ${className}`}
        >
          {" "}
          {children&&children.toUpperCase()}
        </h1>
        <meta name="description" content={children} />
        {/* </Helmet> */}
      </div>
    </>
  );
};
export default Title;
