import { useContext } from "react";
import { LoginContext } from "../../Contexts/LoginContext";

import { Navigate, useLocation } from "react-router";

const RequiresAuth = ({ children }) => {
  const location = useLocation();
  const { isLoggedIn } = useContext(LoginContext);
  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};
export default RequiresAuth;
