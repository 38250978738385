import shivank_prerna from "../../Images/testimonialImages/shivank_prerna.jpg";
import person_placeholder from "../../Images/testimonialImages/person_placeholder.jpg";

const people = [
  {
    id: 4,
    image: shivank_prerna,
    name: "Shivank & Prerna",
    title: "Happy Buyers",
    quote:
      "Autocops have given me the best deal, it was a much easier and smoother deal. Autocops made my life much easier #autocops",
  },
  {
    id: 5,
    image: person_placeholder,
    name: "karan bedi",
    title: "Happy Buyers",
    quote:
      "Wonderful people and always ready to help. Loved the catalogue and the variety nothing like this in Pune. Must visit and great pricing. Lovely employees and staff all seemed very genuine. I visited both showrooms and went back a happy customer.",
  },
  {
    id: 6,
    image: person_placeholder,
    name: "AFarhan Usmani",
    title: "Happy Buyers",
    quote: "Pleasure to have experienced prompt service n best price",
  },
  {
    id: 7,
    image: person_placeholder,
    name: "Anita Gharpuray",
    title: "Happy Buyers",
    quote:
      "I have had a wonderful experience with Autocops. Nitesh, from day 1 was very forthcoming, honest and extremely helpful. He had answers to all my questions. So, far it’s been a hassle free transaction. Sagar was very professional at the time of delivery of the car. I would highly recommend Autocops as a one stop station for your new luxury car.",
  },
  {
    id: 8,
    image: person_placeholder,
    name: "Kamlesh Patil",
    title: "Happy Buyers",
    quote: `“Highly Recommend!”
Highly recommend! went above and beyond to make everything go smoothly! it is amazing!`,
  },
  {
    id: 9,
    image: person_placeholder,
    name: "Biju Nair",
    title: "Happy Buyers",
    quote:
      "One of.the premium Used cars with Detailing facilities in town.  Happy to be here.",
  },
  {
    id: 10,
    image: person_placeholder,
    name: "Sandeep Gavde",
    title: "Happy Buyers",
    quote: "Very nice place to get all types of premium cars optio",
  },
];

export default people;
