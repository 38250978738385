import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/NavBar/Navbar";
import Header from "../../Components/Navbar_2/Header";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import Title from "../../Components/Title/Title";
import styles from "./CustomRequirements.module.css";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../Contexts/DataContext";

const CustomRequirements = () => {
  const { state, dispatch, sendMail } = useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    requirements: "",
  });

  let data, value;
  const getFormData = (event) => {
    data = event.target.name;
    value = event.target.value;
    setFormData({ ...formData, [data]: value });
  };
  console.log(formData);

  const formSubmitHandler = async (event) => {
    const { name, number, email, requirements } = formData;
    event.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        number,
        email,
        requirements,
      }),
    };

    if (name.length > 0 && number.length > 0 && requirements.length > 0) {
      const response = await fetch(
        "https://theautocops-5ac27-default-rtdb.firebaseio.com/contactUs.json",
        options
      );

      console.log(response);

      if (response) {
        alert(
          "Thank you for your interest. Our team will get in touch with you shortly"
        );
        sendMail(formData, "Custom Car Requirement");
      } else {
        alert("error");
      }
      setFormData({
        name: "",
        number: "",
        email: "",
        requirements: "",
      });
    } else {
      alert("Please Fill All The Mandatory Details.");
    }
  };
  return (
    <>
      <ScrollToTopOnMount />
      {/* <Navbar /> */}
      <Header />
      <section className={`${styles.section1} ${styles.ow}`}>
        <div className={styles.container}>
          <Title bg="ow">
            Do you have any specific needs? Please contact us so we can assist
            you more effectively!
          </Title>
        </div>
        <div></div>
      </section>
      <section className={styles.section2}>
        <form>
          <div className={styles.container}>
            <Title>contact us</Title>
          </div>
          <div className={styles.container}>
            <input
              onChange={getFormData}
              value={formData.name}
              type="text"
              name="name"
              placeholder="Enter Customer Name*"
              required
            />
          </div>
          <div className={styles.container}>
            <input
              onChange={getFormData}
              value={formData.number}
              type="tel"
              name="number"
              placeholder="Enter Customer Number*"
              required
            />
          </div>
          <div className={styles.container}>
            <input
              onChange={getFormData}
              value={formData.email}
              type="email"
              name="email"
              placeholder="Enter Customer Email"
            />
          </div>
          <div className={styles.container}>
            <input
              onChange={getFormData}
              value={formData.requirements}
              name="requirements"
              type="text"
              placeholder="Requirements*"
              required
            />
          </div>
          <button className={styles.button} onClick={formSubmitHandler}>
            Submit
          </button>
        </form>
      </section>
      <Footer />
    </>
  );
};
export default CustomRequirements;
