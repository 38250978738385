
import styles from "./SellYourCar.module.css";
import Footer from "../../Components/Footer/Footer";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import Title from "../../Components/Title/Title";
import Accordian from "../../Components/Accordian/Accordian";
import FaqSection from "../../Components/FaqSection/FaqSection";
import SellPageCarousel from "../../Components/SellPageCarousel/SellPageCarousel";
import banner from "../../Images/sellYourCarImages/banner_1.jpg";
import "firebase/storage";
import "firebase/database";
import { Fade } from "react-awesome-reveal";
import React, { useState, useContext, useEffect } from "react";
import { storage } from "../../firebaseConfig";
import { DataContext } from "../../Contexts/DataContext";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Banner from "../../Components/Banner/Banner";
import Header from "../../Components/Navbar_2/Header";
import transparent_valuation from "../../Images/sellYourCarImages/transparent_valuation.png";
import trustworthy_service from "../../Images/sellYourCarImages/trustworthy_service.jpg";
import effortless_process from "../../Images/sellYourCarImages/effortless_process.jpg";
import top_notch_offers from "../../Images/sellYourCarImages/top-notch_offers.jpg";
import { Helmet } from "react-helmet";

const SellYourCar = () => {
  const { state, dispatch, isValidPhoneNumber, sendMail } =
    useContext(DataContext);
  const faqs = [
    {
      question:
        "What details or paperwork about my car do I need handy before getting a valuation quote from your website?",
      answer:
        "To swiftly obtain a valuation quote, keep your RC, Insurance, and clear photos showcasing your vehicle’s exterior, interior, and current odometer reading ready.",
      open: false,
    },
    {
      question:
        "How quickly after I accept your selling price offer can I expect to complete the sale transaction and receive payment?",
      answer:
        "Once you accept our offer, we aim to finalize the sale and provide payment within a remarkable 31-minute timeframe for a seamless and efficient transaction.",
      open: false,
    },
    {
      question:
        " Do you also purchase specialty or classic collector vehicles in addition to more common luxury models to expand your used car inventory?",
      answer:
        "Certainly, our inventory expansion includes specialty and classic collector vehicles, subject to individual conditions and market demand.",
      open: false,
    },
    {
      question:
        " After my car sale valuation, is the quoted price locked-in for a specific period of time before requiring another assessment?",
      answer:
        "Your quoted price remains secure for a convenient 7-day period from the evaluation date, allowing flexibility for sellers.",
      open: false,
    },
    {
      question:
        "If I still owe money on my current auto loan when selling my car to you, can that balance be rolled into my next luxury car purchase?",
      answer:
        "For a streamlined process, ensure your existing car loan is settled, and the new luxury car purchase will involve separate financing arrangements.",
      open: false,
    },
    {
      question:
        "For lease turn-ins, do you handle all inspection, documentation, and process finalization directly with the leasing company?",
      answer:
        "Absolutely, we manage every aspect of the purchase, sales, and aftersales process for lease turn-ins, providing a hassle-free experience for our customers.",
      open: false,
    },
  ];
  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    variant: "",
    name: "",
    number: "",
    email: "",
    message: "",
    imageUrl: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);

    if (section) {
      // Calculate the section's position relative to the viewport
      const offset = section.getBoundingClientRect().top;

      // Scroll to the section with smooth behavior
      window.scroll({
        top: offset + window.scrollY,
        behavior: "smooth",
      });
    }
  }

  const handleSellPageInputChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handleSellPageImageUpload = async (e) => {
    setIsLoading(true);
    const uploadPromises = [];
    const imageUrls = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const storageRef = ref(storage, `sellYourCar/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((downloadURL) => {
          // console.log(`Uploaded: ${file.name}, URL: ${downloadURL}`);
          imageUrls.push(downloadURL);
        });

      uploadPromises.push(uploadTask);
    }

    // Wait for all uploads to complete
    try {
      await Promise.all(uploadPromises);
      alert("images uploaded successfully");
      // console.log("All files uploaded", imageUrls);

      // All URLs are now in 'imageUrls', you can set them in your state
      setFormData({
        ...formData,
        imageUrl: imageUrls, // Or you might want to concatenate with existing URLs
      });
    } catch (error) {
      console.error("Error uploading files", error);
    } finally {
      setIsLoading(false);
    }
  };
  const formSubmitHandler = async (e) => {
    const { brand, model, variant, name, number, email, message, imageUrl } =
      formData;
    e.preventDefault();
    if (
      brand.length > 0 &&
      model.length > 0 &&
      name.length > 0 &&
      variant.length > 0
    ) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/sellYourCar.json",
          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseData = await response.json();
        // console.log(responseData);
        // This will log the unique key generated by Firebase for the new data.
        if (responseData) {
          alert(
            "Thankyou for your interest. our team will get back to you shortly."
          );
          sendMail(formData, "Sell Your Car");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setFormData({
        brand: "",
        model: "",
        variant: "",
        name: "",
        number: "",
        email: "",
        message: "",
        imageUrl: [],
      });
    } else {
      alert("Please complete mandatory fields.");
    }
  };

  const imageInputForm = (
    <>
      <div>
        <input
          type="file"
          multiple
          accept="image/*"
          placeholder="Upload Images"
          onChange={handleSellPageImageUpload}
        />
      </div>
    </>
  );

  return (
    <>
      <ScrollToTopOnMount />
	 

      <header>
        {/* <Navbar /> */}
        <Header />
        <Helmet>
        <title>Sell Your Luxury Cars at Attractive Price | The AutoCops	</title>
        <meta name="description" content="The AutoCops: the ultimate platform for selling luxury cars easily in Mumbai and Pune. Fast, efficient, and reliable – we ensure your premium vehicle finds its perfect match." />
        <meta property="og:title" content="Sell Your Luxury Cars at Attractive Price | The AutoCops" />
        <meta property="og:description" content="The AutoCops: the ultimate platform for selling luxury cars easily in Mumbai and Pune. Fast, efficient, and reliable – we ensure your premium vehicle finds its perfect match." />
      <link rel="canonical" href="https://theautocops.com/sellYourCar"></link>
        </Helmet>
        <Banner bannerStyles={{ paddingTop: "4rem", paddingBottom: "0rem" }} />
      </header>
      <section>
        <Fade delay={100}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={banner} />
            <a
              onClick={() => scrollToSection("form")}
              className={styles.bannerCTA}
            >
              Get Car Value
            </a>
          </div>
          <section className={`${styles.section1} ${styles.ow}`}>
            <div className={styles.container}>
              <Title bg="ow">SELL YOUR LUXURY CAR SEAMLESSLY!</Title>
            </div>
            <div className={styles.container}>
              <p>
              Trade in your used car for a quick instant valuation. Our data-driven pricing technique takes into account your car's condition and current market trends, ensuring a fair and competitive value. Enjoy a seamless, speedy, and transparent process, free of hassles.


              </p>
              <p>
              Sell your luxury car with The Autocops and see the difference. Your time is important to us, and so is your car. That's why we have an instant valuation process that ensures the best deal for you in the quickest possible time. No more waiting; no more uncertainty—just a straight deal based on real data.
Sell your used luxury car with full confidence at The Autocops, as we sincerely believe in the maxim of customer satisfaction and strive for great service delivery. Know your car value instantly today and see how quick and easy it is to sell your luxury used car here.

              </p>
             
            </div>
          </section>
          <section className={styles.section3}>
            {" "}
            <div className={styles.container}>
              <Title>EXCLUSIVE LUXURY CAR PURCHASE PARTNER: SELL YOUR LUXURY CAR IN PUNE</Title>
            </div>
            <div className={styles.container}>
              <ul>
                <li>
                  <span>
                    <strong>Upgrade Your Drive: </strong>
                  </span>{" "}
                  Entrust us with your car for a seamless trade-in experience.
                </li>
                <hr />
                <li>
                  <span>
                    <strong>Quick Valuation Process: </strong>
                  </span>
                  Get an instant, highly competitive price when you sell your luxury car in Pune.
                </li>{" "}
                <hr />
                <li>
                  <span>
                    <strong>Reliable Commitments: </strong>
                  </span>
                  Count on us to uphold our promises without compromising quality. {" "}
                </li>{" "}
                <hr />
                <li>
                  <span>
                    <strong>Effortless Turn-Around: </strong>
                  </span>
                  Experience the quickest stock turn-around time when you sell your luxury car in Pune.{" "}
                </li>{" "}
                <hr />
                <li>
                  <span>
                    <strong>Pricing Strategy: </strong>
                  </span>
                   Our transparent and competitive pricing ensures the best deal when you sell your used luxury car in Pune.{" "}
                </li>{" "}
                <hr />
                <li>
                  <span>
                    <strong>Comprehensive Condition Assessment: </strong>
                  </span>
                  Your car's current state is carefully considered, ensuring a fair evaluation..{" "}
                </li>{" "}
                <hr />
                <li>
                  <span>
                    <strong>Trend-Setting Market Insights: </strong>
                  </span>{" "}
                  Stay ahead with valuations influenced by the latest market
                  trends.{" "}
                </li>
                <hr />
                <li>
                  <span>
                    <strong>Assured Fair Value: </strong>
                  </span>
                  : Our approach guarantees a fair and precise valuation, giving you confidence in your car’s worth.Trust us for a fair and precise valuation when you sell your luxury car in Pune.
                </li>{" "}
              </ul>
            </div>
          </section>
          <section id="form" className={`${styles.section4} ${styles.ow}`}>
            {" "}
            <div className={styles.container}>
              <Title bg="ow">CONNECT WITH OUR EXPERT TEAM </Title>
            </div>
            <form>
              <div>
                <h3>Get in Touch</h3>
                <p>Mandatory fields are denoted by an asterisk (*).</p>
              </div>
              <div className={styles.formContainer}>
                <div>
                  <input
                    value={formData.brand}
                    name="brand"
                    onChange={(e) => handleSellPageInputChange(e, "brand")}
                    type="text"
                    placeholder="BRAND*"
                    required
                  />

                  <input
                    value={formData.model}
                    name="model"
                    onChange={(e) => handleSellPageInputChange(e, "model")}
                    type="text"
                    placeholder="MODEL*"
                    required
                  />
                </div>
                <div>
                  <input
                    value={formData.variant}
                    name="variant"
                    onChange={(e) => handleSellPageInputChange(e, "variant")}
                    type="text"
                    placeholder="VARIANT*"
                    required
                  />

                  <input
                    value={formData.name}
                    name="name"
                    onChange={(e) => handleSellPageInputChange(e, "name")}
                    type="text"
                    placeholder="YOUR NAME*"
                    required
                  />
                </div>
                <div>
                  <input
                    value={formData.number}
                    name="number"
                    onChange={(e) => handleSellPageInputChange(e, "number")}
                    type="tel"
                    placeholder="MOBILE NUMBER*"
                    required
                  />

                  <input
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleSellPageInputChange(e, "email")}
                    type="email"
                    placeholder="EMAIL"
                  />
                </div>{" "}
                <div>
                  <input
                    value={formData.message}
                    name="message"
                    onChange={(e) => handleSellPageInputChange(e, "message")}
                    type="text"
                    placeholder="MESSAGE"
                  />

                  {/* <Accordian
                    logoStyle={{ marginLeft: "0px", display: "none" }}
                    title="UPLOAD IMAGES"
                    titleType="secondary"
                    content={imageInputForm}
                    titleClassName={styles.accordianTitle}
                    contentClassName={styles.accordianContent}
                  /> */}
                  {/* <label className={styles.label}>Upload Car Images*</label> */}

                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    placeholder="Upload Images"
                    onChange={handleSellPageImageUpload}
                  />
                </div>
              </div>{" "}
              <button
                onClick={formSubmitHandler}
                disabled={isLoading}
                className={styles.button}
              >
                {isLoading ? "Uploading..." : "Submit"}
              </button>
            </form>
          </section>
          <section className={`${styles.section5} ${styles.w}`}>
            <div className={styles.container}>
              <Title>Why Choose The Autocops For Your Car Sale?</Title>
            </div>
            <div className={styles.section5Container}>
              <div className={styles.badgeContainer}>
                <img
                  className={styles.section5image}
                  src={trustworthy_service}
                  alt="icon"
                />{" "}
                <label>Trustworthy Service</label>
              </div>{" "}
              <div className={styles.badgeContainer}>
                <img
                  className={styles.section5image}
                  src={effortless_process}
                  alt="icon"
                />{" "}
                <label>Effortless Process</label>
              </div>{" "}
              <div className={styles.badgeContainer}>
                <img
                  className={styles.section5image}
                  src={transparent_valuation}
                  alt="icon"
                />{" "}
                <label>Transparent Valuation</label>
              </div>{" "}
              <div className={styles.badgeContainer}>
                <img
                  className={styles.section5image}
                  src={top_notch_offers}
                  alt="icon"
                />{" "}
                <label>Top-Notch Offers</label>
              </div>
            </div>
          </section>
          <section className={`${styles.section6} ${styles.ow}`}>
            <div className={styles.container}>
              <Title bg="ow">Demystifying Autocops'Workflow</Title>
            </div>
            <SellPageCarousel scrollId="form" />
          </section>
          {/* <section className={`${styles.section7} ${styles.w}`}>
            <div className={styles.container}>
              <Title>pune's most prefered choice for pre-owned cars</Title>
            </div>
            <div className={styles.section7Description}>
              <span>1000+</span>
              <p>Happy Sellers</p>
              <span>Best Price</span>
              <p>Guaranteed</p>
              <span>Swift</span>
              <p>Processing</p>
            </div>
          </section> */}
          <section className={`${styles.faqSection}  ${styles.w}`}>
            <FaqSection titleBg="w" faqArray={faqs} />
          </section>
        </Fade>
      </section>
      <footer>
        <Footer />
      </footer>
    </>
  );
};
export default SellYourCar;
