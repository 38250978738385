import Carousel_2d from "../Carousel_2d/Carousel_2d";
import styles from "./Testimonials.module.css";

const Testimonials = () => {
  return (
    <>
      <section className={styles.container}>
        <Carousel_2d />
      </section>
    </>
  );
};
export default Testimonials;
