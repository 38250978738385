import styles from "./ContactUs.module.css";
import image from "../../Images/contactUsImages/banner_1.jpg";
import Footer from "../../Components/Footer/Footer";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import WhatsappIcon from "../../Components/WhasappIcon/WhatsappIcon";
import CallIcon from "../../Components/CallIcon/CallIcon";
import logoFacebook from "../../Images/logos/facebook.png";
import youTube from "../../Images/logos/youTube.png";
import logoInstagram from "../../Images/logos/insta.jpeg";
import Title from "../../Components/Title/Title";
import { Fade } from "react-awesome-reveal";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../Contexts/DataContext";
import Banner from "../../Components/Banner/Banner";
import Header from "../../Components/Navbar_2/Header";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const { state, dispatch, isValidPhoneNumber, sendMail } =
    useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  const [location, setLocation] = useState("kalyaninagar");

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    requirements: "",
  });

  let data, value;
  const getFormData = (event) => {
    data = event.target.name;
    value = event.target.value;
    setFormData({ ...formData, [data]: value });
  };

  const formSubmitHandler = async (event) => {
    const { name, number, email, requirements } = formData;

    event.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        number,
        email,
        requirements,
      }),
    };
    if (name.length > 0 && requirements.length > 0) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/contactUs.json",
          options
        );

        // console.log(response);

        if (response) {
          alert(
            "Thankyou for your interest. our team will get back to you shortly."
          );
          sendMail(formData, "Contact Us");
        } else {
          alert("error");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setFormData({
        name: "",
        number: "",
        email: "",
        requirements: "",
      });
    } else {
      alert("Please fill all the mandatory Details");
    }
  };

  const dropdownHandler = (event) => {
    setLocation(event.target.value);
  };
  return (
    <>
      <ScrollToTopOnMount />
      {/* <Navbar /> */}
      <Header />
      <Helmet>
        <title>The Autocops: Contact Details	</title>
        <meta name="description" content="Here are the contact details of Autocops for pre-owned/unregistered luxury cars, and detailing, ceramic, & PPF services." />
        <meta property="og:title" content="The Autocops: Contact Details	" />
        <meta property="og:description" content="Here are the contact details of Autocops for pre-owned/unregistered luxury cars, and detailing, ceramic, & PPF services." />
        <link rel="canonical" href="https://theautocops.com/contactUs"/>
        </Helmet>
	       <Banner
        bannerStyles={{ paddingTop: "4rem" }}
        bannerClass={styles.banner}
      />

      <Fade delay={100}>
        {" "}
        {/* <Banner /> */}
        <section className={styles.section1}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={image} alt="contactus" />
          </div>
        </section>
        {/* SECTION - 4 */}
        <section className={`${styles.section4} ${styles.w}`}>
          <div className={styles.container}>
            <Title bg="ow">leave us your details and we'll get in touch</Title>
          </div>
          <h4>Engage in Collaborative Endeavors for Superior Service</h4>
          <form>
            <div className={styles.inputContainer}>
              <input
                onChange={getFormData}
                value={formData.name}
                type="text"
                name="name"
                placeholder="Enter Customer Name*"
                required
              />

              <input
                onChange={getFormData}
                value={formData.number}
                type="tel"
                name="number"
                placeholder="Enter Customer Number*"
                required
              />
            </div>
            <div className={styles.inputContainer}>
              <input
                onChange={getFormData}
                value={formData.email}
                type="email"
                name="email"
                placeholder="Enter Customer Email"
              />

              <input
                onChange={getFormData}
                value={formData.requirements}
                name="requirements"
                type="text"
                placeholder="Requirements*"
                required
              />
            </div>
          </form>{" "}
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={formSubmitHandler}>
              Submit
            </button>
          </div>
        </section>
        {/* section - 2 */}
        <section className={`${styles.section2} ${styles.ow}`}>
          <div className={styles.container}>
            <Title>DRIVING BY? DROP IN!</Title>
          </div>
          <div className={styles.flexContainer}>
            <div>
              <div className={styles.container}>
                <p>Leave your query here :-</p>
                <p>For Preowned/unregistered Luxury Cars:</p>
              </div>
              <div className={styles.container}>
                <a href="tel:+917796660022">7796660022</a>{" "}
                <WhatsappIcon
                  number="7796660022"
                  carTitle="a car"
                  className={styles.whatsappIcon}
                />{" "}
                <CallIcon className={styles.callIcon} number="7796660022" />{" "}
              </div>
              <div className={styles.container}>
                <a href="tel:+918390001122">8390001122</a>{" "}
                <WhatsappIcon
                  number="8390001122"
                  carTitle="a car"
                  className={styles.whatsappIcon}
                />{" "}
                <CallIcon className={styles.callIcon} number="8390001122" />{" "}
              </div>
              <div className={styles.container}>
                <a href="tel:+917721859933">7721859933</a>{" "}
                <WhatsappIcon
                  className={styles.whatsappIcon}
                  number="7721859933"
                  carTitle="a car"
                />{" "}
                <CallIcon className={styles.callIcon} number="7721859933" />
              </div>{" "}
            </div>
            <div>
              <div className={styles.container}>
                <p style={{ marginTop: "1rem" }}>
                  For detailing, ceramic & PPF services:
                </p>
              </div>
              <div className={styles.container}>
                <a href="tel:+918390001122">8390001122</a>{" "}
                <WhatsappIcon
                  className={styles.whatsappIcon}
                  number="8390001122"
                  carTitle="a car"
                />{" "}
                <CallIcon className={styles.callIcon} number="8390001122" />{" "}
              </div>{" "}
              <div className={styles.container}>
                <p style={{ marginTop: "1rem" }}>
                  Feel free to call us between 10:00 am to 10:00 pm
                </p>
              </div>
            </div>
          </div>{" "}
        </section>
        {/* section - 5 */}
        <section className={`${styles.section5} ${styles.w}`}>
          {/* <div className={styles.container}> */}
          <Title
            logoStyle={{ marginLeft: "0px", display: "none" }}
            className={styles.title}
          >
            FOLLOW OUR ADVENTURES HERE
          </Title>
          {/* </div> */}

          <a target="_blank" href="https://www.facebook.com/theautocops/">
            <img src={logoFacebook} />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/theautocops/?hl=en"
          >
            <img src={logoInstagram} />
          </a>
          <a
            target="_blank"
            href="https://youtube.com/@TheAutocops?si=l94oXXtrS_eWKvPn"
          >
            <img src={youTube} />
          </a>
          <WhatsappIcon number="8390001122" carTitle="a car" />
          <CallIcon number="8390001122" />
        </section>
        {/* section - 6 */}
        <section className={`${styles.section6} ${styles.ow}`}>
          <div className={styles.container}>
            <Title>Locate Us</Title>
          </div>

          {/* <div className={styles.dropdownContainer}>
            <select className={styles.dropdown} onChange={dropdownHandler}>
              <option value="kalyaninagar">Kalyani Nagar, Pune</option>
              <option value="wakad">Wakad, Pune</option>
            </select>
          </div> */}
          <div className={styles.mapSection}>
            <div className={styles.mapContainer}>
              <label className={styles.mapLabel}>Kalyani Nagar, Pune</label>
              <iframe
                src={
                  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.5788795810886!2d73.90047930000001!3d18.547922999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c10e7ad2057d%3A0xe5cea7e260265db!2sThe%20Autocops!5e0!3m2!1sen!2sin!4v1695030560583!5m2!1sen!2sin"
                }
                className={styles.map}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
            <div className={styles.mapContainer}>
              <label className={styles.mapLabel}>Wakad, Pune</label>

              <iframe
                src={
                  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.3801998026424!2d73.75453377473706!3d18.601961366683877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b930133bf32d%3A0x8bd3aad74b64c161!2sThe%20Autocops!5e0!3m2!1sen!2sin!4v1695031931624!5m2!1sen!2sin"
                }
                className={styles.map}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </section>
      </Fade>
      <Footer />
    </>
  );
};
export default ContactUs;
