// import img from "../../Images/Bg/bg.jpg";
import USP_1 from "../../Images/USPimages/USP_1.jpg";
import USP_2 from "../../Images/USPimages/USP_2.jpg";
import USP_3 from "../../Images/USPimages/USP_3.jpg";
import USP_4 from "../../Images/USPimages/USP_4.jpg";

// import img from "../../Images/Bg/slider.jpg";

export const carData = [
  {
    key: 1,
    carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
    fuelType: "Diesel",
    transmission: "Automatic",
    odometerReading: "10000",
    askingPrice: "8499999",
  },
  {
    key: 2,
    carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
    fuelType: "Diesel",
    transmission: "Automatic",
    odometerReading: "10000",
    askingPrice: "8499999",
  },
  {
    key: 3,
    carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
    fuelType: "Diesel",
    transmission: "Automatic",
    odometerReading: "10000",
    askingPrice: "8499999",
  },
  {
    key: 4,
    carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
    fuelType: "Diesel",
    transmission: "Automatic",
    odometerReading: "10000",
    askingPrice: "8499999",
  },
  {
    key: 5,
    carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
    fuelType: "Diesel",
    transmission: "Automatic",
    odometerReading: "10000",
    askingPrice: "8499999",
  },
  // {
  //   key: 6,
  //   carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
  //   fuelType: "Diesel",
  //   transmission: "Automatic",
  //   odometerReading: "10000",
  //   askingPrice: "8499999",
  // },
  // {
  //   key: 7,
  //   carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
  //   fuelType: "Diesel",
  //   transmission: "Automatic",
  //   odometerReading: "10000",
  //   askingPrice: "8499999",
  // },
  // {
  //   key: 8,
  //   carTitle: "Mercedes Benz GLC Coupe 300d 4MATIC",
  //   fuelType: "Diesel",
  //   transmission: "Automatic",
  //   odometerReading: "10000",
  //   askingPrice: "8499999",
  // },
];

export const UspData = [
  { key: 1, badge: "30+", strongText: "Luxury Car Brands", image: USP_1 },
  {
    key: 2,
    badge: "1000+",
    strongText: "Happy Customers",
    image: USP_2,
  },
  { key: 3, badge: "Pune's", strongText: "Biggest Studio", image: USP_3 },
  { key: 4, badge: "30+", strongText: "Luxury Car Brands", image: USP_1 },
  { key: 5, badge: "200+", strongText: "Checkpoints", image: USP_4 },
];
