// firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBlLG1ogt8SaVyS8LjU549Q3agtdEaUtSU",
  authDomain: "theautocops-5ac27.firebaseapp.com",
  databaseURL: "https://theautocops-5ac27-default-rtdb.firebaseio.com",
  projectId: "theautocops-5ac27",
  storageBucket: "theautocops-5ac27.appspot.com",
  messagingSenderId: "53995752904",
  appId: "1:53995752904:web:c59e1d8d495cb0b67d84fd",
  measurementId: "G-GDKDX4X4JT",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { app, storage };
