import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import CarouselCard from "../CarouselCard/CarouselCard";
import { v4 } from "uuid";
import { config } from "react-spring";
import styles from "./Carousel_3d.module.css";
// import image from "../../Images/Bg/slider.jpg";
import { carData, UspData } from "./Data";
import Title from "../Title/Title";

export const Carousel_3d = () => {
  // console.log(data);
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  });

  const slides = UspData.map((slideItem) => {
    return {
      key: slideItem.key,
      content: (
        <CarouselCard>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={slideItem.image} alt="car" />
          </div>
          <div className={styles.data}>
            <p>
              {slideItem.badge}
              <strong className={styles.strong}> {slideItem.strongText}</strong>
            </p>
          </div>
        </CarouselCard>
      ),
    };
  }).map((slide, index) => {
    return { ...slide, onClick: () => setState({ goToSlide: index }) };
  });
  // let slides = [
  //   {
  //     key: 1,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="1" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>

  //           <div className={styles.description}>Diesel</div>
  //           <div className={styles.description}>Automatic</div>
  //           <div className={styles.description}>10000 kms</div>
  //           <div className={styles.description}>Asking Price – ₹ 8499999</div>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 2,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="2" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 3,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="3" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 4,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="4" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 5,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="5" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 6,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="6" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 7,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="7" />
  //         </div>{" "}
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  //   {
  //     key: 8,
  //     content: (
  //       <CarouselCard>
  //         <div className={styles.imageContainer}>
  //           <img className={styles.image} src={image} alt="8" />
  //         </div>
  //         <div>
  //           <div className={styles.carTitle}>
  //             Mercedes Benz GLC Coupe 300d 4MATIC
  //           </div>
  //           <p className={styles.description}>
  //             10000 kms, Diesel, Automatic, Asking Price – ₹ 8499999
  //           </p>
  //         </div>
  //         <div>
  //           <button className={styles.button}>Show More</button>
  //         </div>
  //       </CarouselCard>
  //     ),
  //   },
  // ].map((slide, index) => {
  //   return { ...slide, onClick: () => setState({ goToSlide: index }) };
  // });

  // const onChangeInput = (e) => {
  //   setState({
  //     [e.target.name]: parseInt(e.target.value, 10) || 0,
  //   });
  // };

  let xDown = null;
  let yDown = null;

  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        setState({ goToSlide: state.goToSlide + 1 });
      } else {
        /* right swipe */
        setState({ goToSlide: state.goToSlide - 1 });
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  return (
    <>
      <div>
        <div style={{ textAlign: "left", margin: "0rem 1.5rem" }}>
          {/* <Title className={styles.title}>Market Marvels</Title> */}
        </div>
        <div
          className={styles.carouselContainer}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <Carousel
            slides={slides}
            goToSlide={state.goToSlide}
            offsetRadius={state.offsetRadius}
            // showNavigation={state.showNavigation}
            animationConfig={state.config}
          />
          <div
            style={{
              margin: "0 auto",
              width: "50%",
              display: "flex",
              justifyContent: "space-around",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};
