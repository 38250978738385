import styles from "./SearchSection.module.css";
import Title from "../Title/Title";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../Contexts/DataContext";
import { useNavigate } from "react-router-dom";

const SearchSection = ({ title, carData }) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(DataContext);
  const [filteredCarData, setFilteredCarData] = useState(state.allStock);
  const [search, setSearch] = useState({ make: "", bodyType: "" });

  const submitHandler = (event) => {
    event.preventDefault();

    // console.log(search);

    if (search.make.length > 0 && search.bodyType.length > 0) {
      const SearchByTitle = carData.filter((car) => {
        const carTitleLowerCase = car.carTitle.toLowerCase();
        const makeLowerCase = search.make.toLowerCase();

        if (
          makeLowerCase === "jaguar land rover" &&
          (carTitleLowerCase.includes("jaguar") ||
            carTitleLowerCase.includes("range") ||
            carTitleLowerCase.includes("rover"))
        ) {
          return true;
        } else if (
          car.luxury === "no" &&
          search.make === "Other Brands (All other non luxury brands)"
        ) {
          return true;
        }
        // For other queries, filter the cars according to the selected make
        else if (carTitleLowerCase.includes(makeLowerCase)) {
          return true;
        }
      });
      // console.log(SearchByTitle);

      const searchByBodyType = SearchByTitle.filter((titleFilteredCar) => {
        if (
          titleFilteredCar.bodyType
            .toLowerCase()
            .includes(search.bodyType.toLowerCase())
        ) {
          return true;
        } else if (
          search.bodyType === "All"
          //&& titleFilteredCar.luxury === "yes"
        ) {
          return true;
        }
      });
      // console.log(searchByBodyType);
      // setFilteredCarData(searchByBodyType);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: searchByBodyType });
    }

    if (search.make.length > 0 && !(search.bodyType.length > 0)) {
      const Search = carData.filter((car) => {
        const carTitleLowerCase = car.carTitle.toLowerCase();
        const makeLowerCase = search.make.toLowerCase();

        // If user selects 'jaguar land rover', include cars with titles containing 'jaguar', 'land', or 'rover'
        if (
          makeLowerCase === "jaguar land rover" &&
          (carTitleLowerCase.includes("jaguar") ||
            carTitleLowerCase.includes("range") ||
            carTitleLowerCase.includes("rover"))
        ) {
          return true;
        } else if (
          car.luxury === "no" &&
          search.make === "Other Brands (All other non luxury brands)"
        ) {
          return true;
        }
        // For other queries, filter the cars according to the selected make
        else if (carTitleLowerCase.includes(makeLowerCase)) {
          return true;
        }

        return false;
      });
      // console.log(Search);
      // setFilteredCarData(search);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: Search });
    }

    if (!(search.make.length > 0) && search.bodyType.length > 0) {
      const Search = carData.filter((car) => {
        if (search.bodyType === "All") {
          return true;
        }
        if (
          car.bodyType.toLowerCase().includes(search.bodyType.toLowerCase())
        ) {
          return true;
        }
      });
      // console.log(Search);
      // setFilteredCarData(search);
      dispatch({ type: "SET_FILTERED_CAR_DATA", payload: Search });
    }
    navigate("/stock");

    // dispatch({ type: "SET_SEARCH", payload: filteredCarData });
  };

  // useEffect(() => {}, [filteredCarData]);

  return (
    <>
      <section className={styles.section1}>
        <div className={styles.container}>
          <Title bg="ow">{title}</Title>
          <p>
          Have you been searching for a top quality used luxury car? Find the perfect car at The Autocops. At The Autocops, we know that you consider value when you think about used luxury cars in Pune and so we take initiative to make sure all our cars meet your standards. 
          </p>
        </div>

        <div className={styles.container}></div>
        <div className={styles.accordionContainer}>
          <form>
            <h4>
              Look Out For Our Exclusive Collection of{" "}
              <strong>Premium Cars</strong>{" "}
            </h4>

            <div>
              <select
                onChange={(event) =>
                  setSearch({ ...search, make: event.target.value })
                }
                className={styles.dropdown}
              >
                <option>Make</option>
                <option>Mercedes-Benz</option>
                <option>BMW</option>
                {/* <option>Range Rover</option> */}
                <option>Jaguar Land Rover</option>
                {/* <option>Jaguar</option> */}
                <option>Audi</option>
                <option>Porsche</option>
                <option>Lexus</option>
                <option>Maserati</option>

                <option>Toyota Land Cruiser</option>

                <option>Other Brands (All other non luxury brands)</option>
              </select>
            </div>
            <div>
              <select
                onChange={(event) =>
                  setSearch({ ...search, bodyType: event.target.value })
                }
                className={styles.dropdown}
              >
                <option>Body Type</option>
                <option>Sedan</option>
                <option>SUV</option>
                <option>Hatchback</option>
                <option>Convertible</option>
                <option>Sports</option>
                <option>Coupe</option>
                <option>MUV</option>
                <option>All</option>
              </select>
            </div>

            <button className={styles.button} onClick={submitHandler}>
              Search
            </button>
          </form>
        </div>
      </section>
    </>
  );
};
export default SearchSection;
