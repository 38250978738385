import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import styles from "./PDPCarousel_3d.module.css";
import PDPSlideCard from "../PDPSlideCard/PDPSlideCard";
import { Modal, ModalContent } from "../../Components/Modal/Modal";
import { carData } from "../3dCarousel/Data";

export const PDPCarousel_3d = ({ data }) => {
  const [images, setImages] = useState([
    ...data[0].interiorImageUrl,
    ...data[0].exteriorImageUrl,
    ...data[0].descriptionImageUrl,
  ]);
  // console.log(data);
  const slideData = images.map((img, index) => ({
    key: index + 1,
    carImage: img,
  }));
  
  // console.log(slideData);

  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
  });

  const [isOpen, setIsopen] = useState(false);
  const showModal = () => setIsopen((prev) => !prev);

  const slides = slideData
    .map((slideItem) => {
      return {
        key: slideItem.key,
        content: (
          <PDPSlideCard>
            {/* <Modal onOpen={showModal}> */}
            <div className={styles.imageContainer}>
              <img id={styles.image} src={slideItem.carImage} alt="car" />
            </div>
            {/* </Modal> */}
            {/* {isOpen && (
              <ModalContent onClose={() => setIsopen(false)}>
                <img id={styles.image} src={slideItem.carImage} alt="car" />
              </ModalContent>
            )} */}
          </PDPSlideCard>
        ),
      };
    })
    .map((slide, index) => {
      return { ...slide, onClick: () => setState({ goToSlide: index }) };
    });

  let xDown = null;
  let yDown = null;

  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        setState({ goToSlide: state.goToSlide + 1 });
      } else {
        /* right swipe */
        setState({ goToSlide: state.goToSlide - 1 });
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  return (
    <>
      <div>
        <div
          className={styles.carouselContainer}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <Carousel
            slides={slides}
            goToSlide={state.goToSlide}
            offsetRadius={state.offsetRadius}
            // showNavigation={state.showNavigation}
            animationConfig={state.config}
          />
        </div>
      </div>
    </>
  );
};
