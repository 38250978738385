import workflow_1 from "../../Images/sellYourCarImages/DEMYSTIFYING_2.jpg";
import workflow_2 from "../../Images/sellYourCarImages/DEMYSTIFYING_3.jpg";
import workflow_3 from "../../Images/sellYourCarImages/DEMYSTIFYING_1.jpg";

const people = [
  {
    id: 1,
    image: workflow_1,
    name: "ONLINE CAR VALUATION",
    title: "CEO",
    quote: "Enter car details and get a valuation.",
  },
  {
    id: 2,
    image: workflow_2,
    name: "VISIT BRANCH",
    title: "CEO",
    quote: "Visit branch and get an offer.",
  },
  {
    id: 3,
    image: workflow_3,
    name: "SWIFT TRANSACTION",
    title: "PM",
    quote: "Sell car in a single visit.",
  },
];

export default people;
