import styles from "./EmiCalculator.module.css";
import Title from "../Title/Title";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const EmiCalculator = () => {
  const [data, setData] = useState({ amount: 0, months: 0, interest: 0 });
  const navigate = useNavigate();
  const submitHandler = (event) => {
    event.preventDefault();
    navigate("/calculateEmi", { state: { inputData: data } });
  };

  return (
    <>
      <section className={styles.section}>
        <Title className={styles.heading}>Calculate your EMI</Title>
        <div>
          <form>
            <p>
            Plan smartly with The Autocops' EMI Calculator. Simply input your details and let our calculator do the rest, offering clear, accurate estimates instantly.
            </p>
            <>
              <p>
              Get instant, accurate estimates hassle-free. Say goodbye to complexity and uncertainty on your path to owning your dream car.
              </p>{" "}
            </>

            {/* <div>
              <input
                onChange={(event) =>
                  setData({ ...data, amount: event.target.value })
                }
                className={styles.input}
                type="number"
                placeholder="Loan amount"
              />{" "}
            </div>
            <div>
              <input
                onChange={(event) =>
                  setData({ ...data, months: event.target.value })
                }
                className={styles.input}
                type="number"
                placeholder="Months"
              />{" "}
            </div>
            <div>
              <input
                onChange={(event) =>
                  setData({ ...data, interest: event.target.value })
                }
                className={styles.input}
                type="number"
                placeholder="Interest %"
              />{" "}
            </div> */}

            <button className={styles.button} onClick={submitHandler}>
              Calculate
            </button>
          </form>{" "}
        </div>
      </section>
    </>
  );
};
export default EmiCalculator;
