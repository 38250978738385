import Header from "../../Components/Navbar_2/Header";
import Banner from "../../Components/Banner/Banner";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import styles from "./ProductDescriptionPage.module.css";
import Title from "../../Components/Title/Title";
import WhatsappIcon from "../../Components/WhasappIcon/WhatsappIcon";
import CallIcon from "../../Components/CallIcon/CallIcon";
import logoFacebook from "../../Images/logos/facebook.png";
import youTube from "../../Images/logos/youTube.png";
import logoInstagram from "../../Images/logos/insta.jpeg";
import logoTwitter from "../../Images/logos/twitter.png";
import Footer from "../../Components/Footer/Footer";
import FaqSection from "../../Components/FaqSection/FaqSection";
import { useState, useEffect, useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { useParams } from "react-router-dom";
import { DataContext } from "../../Contexts/DataContext";
import { PDPCarousel_3d } from "../../Components/PDPCarousel_3d/PDPCarousel_3d";
import SliderComponent from "../../Components/SliderComponent/SliderComponent";

const ProductDescriptionPage = () => {
  const {
    state,
    getStockDescriptionData,
    getStock,
    isValidPhoneNumber,
    sendMail,
  } = useContext(DataContext);
  const { id } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [carData, setCarData] = useState();
  const [loading, setLoading] = useState(true);

  
  function replaceHyphensWithSpaces(input) {
    return input.replace(/-/g, ' ');
  }
  const modifiedId = replaceHyphensWithSpaces(id);

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    requirements: `I wanted to enquire regarding ${modifiedId}`,
  });
  // console.log(id);

  let data, value;
  const getFormData = (event) => {
    data = event.target.name;
    value = event.target.value;
    setFormData({ ...formData, [data]: value });
  };

  const formSubmitHandler = async (event) => {
    const { name, number, email, requirements } = formData;

    event.preventDefault();

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        number,
        email,
        requirements,
      }),
    };
    if (name.length > 0 && requirements.length > 0) {
      if (isValidPhoneNumber(number)) {
        const response = await fetch(
          "https://theautocops-5ac27-default-rtdb.firebaseio.com/productDescriptionPageFormData.json",
          options
        );

        // console.log(response);

        if (response) {
          alert(
            "Thank you for your interest. Our team will get in touch with you shortly"
          );
          sendMail(formData, "PDP");
        } else {
          alert("error");
        }
      } else {
        alert("Invalid Phone Number");
      }

      setFormData({
        name: "",
        number: "",
        email: "",
        requirements: "",
      });
    } else {
      alert("Please fill all the mandatory Details");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    // getStockDescriptionData();
    if (state.stockDescription.length > 0) {
      const carData = state.stockDescription.filter(
        (car) => car.carTitle.toUpperCase() === modifiedId.toUpperCase()
      );
      // console.log(carData);
      setCarData(carData);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [state.stockDescription]);

  const [display, setDisplay] = useState("");

  const faqs = [
    {
      question:
        " What inspection checklist and criteria do you use to select vehicles for your luxury used car inventory?",
      answer:
        "Our luxury used cars undergo a meticulous 360-degree inspection and certification by Carwale and Cartrade, featuring a rigorous 167-point quality check.",
      open: false,
    },
    {
      question:
        " Do you have any financing partnerships or special programs for first-time luxury vehicle buyers?",
      answer:
        "The Autocops has financing partnerships with leading banks and NBFCs, offering tailor-made schemes for the unique needs of first-time luxury vehicle buyers.",
      open: false,
    },
    {
      question:
        " Do you provide warranty options for your pre-owned luxury vehicles?",
      answer:
        "Yes, we offer extended warranties at an additional cost for pre-owned luxury vehicles beyond the original manufacturer's warranty.",
      open: false,
    },
    {
      question:
        "Can I trade in my current vehicle for a luxury car at your dealership?",
      answer:
        "Absolutely, you can trade in any passenger car toward the purchase of your luxury car, providing a seamless and convenient experience.",
      open: false,
    },
    {
      question:
        "Do you offer extended service contracts or protection plans for luxury vehicles?",
      answer:
        "Yes, The Autocops offers extended service contracts and warranty options at an additional cost, ensuring comprehensive protection for your luxury vehicle.",
      open: false,
    },
    {
      question:
        "For selling customers, what documentation or verification is needed for getting a quote?",
      answer:
        "To get the best quote for your vehicle, we require documents such as the Registration Certificate, Valid Insurance, Vehicle Service History, Purchase, and Service Invoices, Extended Warranty, Service Plan certificates, and an in-person vehicle inspection.",
      open: false,
    },
  ];

console.log(carData,"carData");

  return (
    <>
      <ScrollToTopOnMount />
      <Header />
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <Fade delay={100}>
          <section className={`${styles.section1} ${styles.ow}`}>
            <div className={styles.container}>
              {/* <Title bg="ow">{id}</Title> */}
              <Title bg="ow">{carData[0]?.carTitle}</Title>
              {/* <h1 bg="ow">{carData[0]?.carTitle}</h1> */}
            </div>
            <div className={styles.container}>
              {/* <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet
              illo consectetur, exercitationem cumque totam iusto maiores soluta
              laudantium quasi laborum nulla doloremque cupiditate quia neque
              officiis ut aut itaque ducimus.
            </p> */}
            </div>
          </section>

          <section className={styles.section2}>
            <PDPCarousel_3d data={carData} />
            {/* <SliderComponent data={carData}/> */}
          </section>

          <section className={`${styles.section3} ${styles.w}`}>
            <div className={styles.filterContainer}>
              <ul className={styles.filters}>
                <li
                  className={styles.options}
                  onClick={() =>
                    screenWidth > 998
                      ? setDisplay(
                          <section className={styles.description}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Condition</strong>
                                  </td>
                                  <td>{carData[0].condition}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Year</strong>
                                  </td>
                                  <td>{carData[0].manufacturing}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Odometer Reading</strong>
                                  </td>
                                  <td>{carData[0].odometerReading}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Registration Date</strong>
                                  </td>
                                  <td>{carData[0].registration}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Manufacturing</strong>
                                  </td>
                                  <td>{carData[0].manufacturing}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Color</strong>
                                  </td>
                                  <td>{carData[0].colorExterior}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Color Interior</strong>
                                  </td>
                                  <td>{carData[0].colorInterior}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Engine Type</strong>
                                  </td>
                                  <td>{carData[0].fuelType}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Registration Type</strong>
                                  </td>
                                  <td>{carData[0].registrationType}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Insurance</strong>
                                  </td>
                                  <td>{carData[0].insuranceType}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Insurance Upto</strong>
                                  </td>
                                  <td>{carData[0].insuranceUpto}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Transmission</strong>
                                  </td>
                                  <td>{carData[0].transmission}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Assembly</strong>
                                  </td>
                                  <td>{carData[0].assemblyType}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Warranty</strong>
                                  </td>
                                  <td>{carData[0].warranty}</td>
                                </tr>{" "}
                                <tr>
                                  <td>
                                    <strong>Location</strong>
                                  </td>
                                  <td>{carData[0].location}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Asking Price</strong>
                                  </td>
                                  <td>{carData[0].askingPrice}</td>
                                </tr>
                              </tbody>
                            </table>
                          </section>
                        )
                      : setDisplay(
                          <>
                            <section className={styles.description}>
                              <li>
                                <strong>Condition</strong> :{" "}
                                {carData[0].condition}
                              </li>
                              <li>
                                <strong>Year</strong> :{" "}
                                {carData[0].manufacturing}
                              </li>
                              <li>
                                <strong>Odometer Reading</strong> :{" "}
                                {carData[0].odometerReading} KMS
                              </li>
                              <li>
                                <strong>Registration Date</strong> :{" "}
                                {carData[0].registration}
                              </li>
                              <li>
                                <strong>Manufacturing</strong> :{" "}
                                {carData[0].manufacturing}
                              </li>
                              <li>
                                <strong>Color</strong> :{" "}
                                {carData[0].colorExterior}
                              </li>
                              <li>
                                <strong>Color Interior</strong> :{" "}
                                {carData[0].colorInterior}
                              </li>
                              <li>
                                <strong>Engine Type</strong> :{" "}
                                {carData[0].fuelType}
                              </li>
                              <li>
                                <strong>Registration Type</strong> :{" "}
                                {carData[0].registrationType}
                              </li>
                              <li>
                                <strong>Insurance</strong> :{" "}
                                {carData[0].insuranceType}
                              </li>
                              <li>
                                <strong>Insurance Upto</strong> :{" "}
                                {carData[0].insuranceUpto}
                              </li>
                              <li>
                                <strong>Transmission</strong> :{" "}
                                {carData[0].transmission}
                              </li>
                              <li>
                                <strong>Assembly</strong> :{" "}
                                {carData[0].assemblyType}
                              </li>
                              <li>
                                <strong>Warranty</strong> :{" "}
                                {carData[0].warranty}
                              </li>
                              <li>
                                <strong>Location</strong> :{" "}
                                {carData[0].location}
                              </li>
                              <li>
                                <strong>Asking Price</strong> :{" "}
                                {carData[0].askingPrice}
                              </li>
                            </section>
                          </>
                        )
                  }
                >
                  Description
                </li>
                <li
                  className={styles.options}
                  onClick={() => {
                    const featuresArray = carData[0].features.split(",");
                    const features = (
                      <>
                        <section className={styles.features}>
                          {featuresArray.map((feature) => (
                            <li>{feature}</li>
                          ))}
                        </section>
                      </>
                    );
                    setDisplay(features);
                  }}
                >
                  Features
                </li>
                <li
                  className={styles.options}
                  onClick={() =>
                    setDisplay(
                      <>
                        <ul className={styles.images}>
                          {carData[0]?.exteriorImageUrl.map((url) =>(
                            // console.log(url);
                            <li>
                              <img
                                src={url}
                                className={styles.exterior}
                                alt="exterior Image"
                              />
                            </li>
                          ))}
                        </ul>
                      </>
                    )
                  }
                >
                  Exterior
                </li>
                <li
                  className={styles.options}
                  onClick={() =>
                    setDisplay(
                      <>
                        <ul className={styles.images}>
                          {/* {carData[0]?.interiorImageUrl.map((url) => ( */}
                          {carData[0] && carData[0].exteriorImageUrl && carData[0].exteriorImageUrl.map((url) => (
                            <li>
                              <img
                                src={url}
                                className={styles.interior}
                                alt="interior Image"
                                onError={(e) => { console.error('Image load error:', e.target.src); }}
                              />
                            </li>
                          ))}
                        </ul>
                      </>
                    )
                  }
                >
                  Interior
                </li>
              </ul>
            </div>
            <div className={styles.output}>{display}</div>
          </section>

          <section className={`${styles.section7} ${styles.ow}`}>
            <div className={styles.container}>
              <Title className={styles.title} bg="ow">
                leave us your details and we'll get in touch
              </Title>
            </div>
            <h4>Help us serve you better</h4>
            <form>
              <div className={styles.inputContainer}>
                <input
                  onChange={getFormData}
                  value={formData.name}
                  type="text"
                  name="name"
                  placeholder="Enter Customer Name*"
                  required
                />

                <input
                  onChange={getFormData}
                  value={formData.number}
                  type="tel"
                  name="number"
                  placeholder="Enter Customer Number*"
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <input
                  onChange={getFormData}
                  value={formData.email}
                  type="email"
                  name="email"
                  placeholder="Enter Customer Email"
                />

                <input
                  onChange={getFormData}
                  value={formData.requirements}
                  name="requirements"
                  type="text"
                  placeholder="Requirements*"
                  required
                />
              </div>
            </form>{" "}
            <div className={styles.buttonContainer}>
              <button className={styles.button} onClick={formSubmitHandler}>
                Submit
              </button>
            </div>
          </section>

          <section className={`${styles.section4} ${styles.w}`}>
            <div>
              <Title
                logoStyle={{ display: "none" }}
                className={styles.socialTitle}
              >
                get in touch with us
              </Title>
            </div>

            <a target="_blank" href="https://www.facebook.com/theautocops/">
              <img src={logoFacebook} />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/theautocops/?hl=en"
            >
              <img src={logoInstagram} />
            </a>
            <a
              target="_blank"
              href="https://youtube.com/@TheAutocops?si=l94oXXtrS_eWKvPn"
            >
              <img src={youTube} />
            </a>
            <WhatsappIcon number="8390001122" carTitle={modifiedId} />
            <CallIcon number="8390001122" />
          </section>

          <section className={`${styles.section5} ${styles.ow}`}>
            <FaqSection faqArray={faqs} title="ow" titleBg="ow" />
          </section>

          <section className={styles.section6}></section>
        </Fade>
      )}
      {/* <Banner /> */}
      <Footer />
    </>
  );
};

export default ProductDescriptionPage;
