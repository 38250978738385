import nitesh from "../../Images/ceoImages/Nitesh_Sharma.jpg";
import nikhil from "../../Images/ceoImages/Nikhil_Kulkarni.jpg";

const people = [
  {
    id: 1,
    image: nitesh,
    name: "Nitesh Sharma",
    title: "CEO",
    quote: "Opportunities don't happen, You create them.",
  },
  {
    id: 2,
    image: nikhil,
    name: "Nikhil Kulkarni",
    title: "CEO",
    quote: "We live by choice, love by passion and win hearts by profession.",
  },
];

export default people;
