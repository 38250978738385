import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/NavBar/Navbar";
import Title from "../../Components/Title/Title";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import styles from "./TermsAndConditions.module.css";
import Header from "../../Components/Navbar_2/Header";
import { useEffect, useContext } from "react";
import { DataContext } from "../../Contexts/DataContext";

const TermsAndConditions = () => {
  const { state, dispatch } = useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  return (
    <>
      <ScrollToTopOnMount />
      {/* <Navbar /> */}
      <Header />
      <section className={styles.section1}>
        <div className={styles.container}>
          <Title>The AutoCops (Terms and conditions Page)</Title>
        </div>
        <div className={styles.container}>
          <p>
            This website is operated by TheAutocops Pvt. Ltd. Throughout the
            site, the terms “we”, “us” and “our” refer to TheAutocops Pvt. Ltd.
            TheAutocops Pvt. Ltd. offers this website, including all
            information, tools and services available from this site to you, the
            user, conditioned upon your acceptance of all terms, conditions,
            policies and notices stated here.
          </p>
          <li>
            {" "}
            By visiting our site and/ or purchasing something from us, you
            engage in our “Service” and agree to be bound by the following terms
            and conditions (“Terms of Service”, “Terms”), including those
            additional terms and conditions and policies referenced herein
            and/or available by hyperlink.
          </li>
          <li>
            {" "}
            These Terms of Service apply to all users of the site, including
            without limitation users who are browsers, vendors, customers,
            merchants, and/ or contributors of content. Please read these Terms
            of Service carefully before accessing or using our website.
          </li>
          <li>
            {" "}
            By accessing or using any part of the site, you agree to be bound by
            these Terms of Service. If you do not agree to all the terms and
            conditions of this agreement, then you may not access the website or
            use any services. If these Terms of Service are considered an offer,
            acceptance is expressly limited to these Terms of Service.
          </li>
          <li>
            {" "}
            Any new features or tools which are added to the current store shall
            also be subjected to the Terms of Service. You can review the most
            current version of the Terms of Service at any time on this page.
          </li>
          <li>
            {" "}
            We reserve the right to update, change or replace any part of these
            Terms of Service by posting updates and/or changes to our website.
            It is your responsibility to check this page periodically for
            changes. Your continued use of or access to the website following
            the posting of any changes constitutes acceptance of those changes.
          </li>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
