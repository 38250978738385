import styles from "./CallIcon.module.css";
import call from "../../Images/logos/call.png";

const CallIcon = ({ style, number, className }) => {
  return (
    <>
      <a href={`tel:+91${number}`}>
        <img
          style={{ ...style }}
          className={className || styles.image}
          src={call}
          alt="whatsapp"
        />
      </a>
    </>
  );
};
export default CallIcon;
