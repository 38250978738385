import Footer from "../../Components/Footer/Footer";
import styles from "./AboutUs.module.css";
import { Helmet } from "react-helmet";

import image from "../../Images/aboutUsImages/banner_1.jpg";
import mission from "../../Images/aboutUsImages/mission_&_vision.jpg";
import story from "../../Images/aboutUsImages/foundingStory.jpg";
import coreValues from "../../Images/aboutUsImages/coreValues.jpg";
import ScrollToTopOnMount from "../../Components/ScrollToTopOnMount/ScrollToTopOnMount";
import Title from "../../Components/Title/Title";
import MeetTheTeam from "../../Components/MeetTheTeam/MeetTheTeam";
import { Fade } from "react-awesome-reveal";
import { Carousel_3d } from "../../Components/3dCarousel/Carousel_3d";
import Banner from "../../Components/Banner/Banner";
import Header from "../../Components/Navbar_2/Header";
import { useEffect, useContext } from "react";
import { DataContext } from "../../Contexts/DataContext";


const AboutUs = () => {
  const { state, dispatch } = useContext(DataContext);

  useEffect(() => {
    dispatch({
      type: "SET_FILTERED_CAR_DATA",
      payload: [],
    });
  }, []);

  return (
    <>

      <Helmet>
        <title>The Autocops: Our Story of Excellence in Luxury Car Sales</title>
        <meta
          name="description"
          content="Discover Autocops' journey since 2019, making luxury car ownership easier. Find your dream car with exceptional service."
        />
        <link rel="canonical" href="https://theautocops.com/aboutUs"></link>
      </Helmet>

      <ScrollToTopOnMount />
      {/* <Navbar /> */}
      <Header />

      <Banner
        bannerStyles={{ paddingTop: "4rem" }}
        bannerClass={styles.banner}
      />

      <Fade delay={100}>
        <section className={styles.section1}>
          <img className={styles.image} src={image} alt="contactus" />
        </section>

        <section className={`${styles.section2} ${styles.ow}`}>
          <div className={styles.container}>
            <Title bg="ow" className={styles.title}>
              FOUNDING STORY: DRIVING DREAMS INTO REALITY AT THE AUTOCOPs
            </Title>
          </div>
          <div className={styles.container}>
            <p>
              Our story at The Autocops began with a vision, rooted in 2019 with
              rich experience in automobile sales, after-sales, and a steadfast
              commitment to customer delight. This vision transformed into a
              reality with the inception of The Autocops, where we don't just
              sell cars; we forge lifelong relationships.{" "}
            </p>{" "}
            <div className={styles.imgContainer}>
              <span>
                <img className={styles.descriptionImage} src={story} />
              </span>
            </div>{" "}
            <p>
              Driven by the ethos of 'Connecting with Your Dreams' and 'You Are
              Our Priority,' we embarked on this journey to redefine luxury car
              ownership. Our team, bringing invaluable expertise from
              prestigious brands like Mercedes-Benz, BMW, and Audi, ensures
              every customer interaction is as unique as the cars we offer.
            </p>
            <p>
              At The Autocops, we seamlessly integrate competitive pricing,
              unparalleled service quality, and a nationwide network of
              delighted customers, enhancing your journey through our expansive
              selection of pre-owned luxury cars. Our journey is a testament to
              our dedication to excellence, where every customer's dream car
              becomes a reality.
            </p>
          </div>
          <br />
        </section>

        <section className={styles.section3}>
          <div className={styles.container}>
            <Title bg="w" className={styles.title}>
              MISSION & VISION: DRIVING FORWARD WITH PURPOSe
            </Title>
          </div>
          <div className={styles.container}>
            <p>
              Driven by a profound understanding of our discerning clientele and
              the automotive market, we at The Autocops are dedicated to
              redefining the luxury car experience. Our MISSION is to curate an
              unparalleled selection of vehicles, providing exceptional service
              and expertise to connect individuals with their dream luxury cars
              that reflect their aspirations for luxury and sophistication,
              setting new standards of distinction in the industry.
            </p>{" "}
            <div className={styles.imgContainer}>
              <span>
                <img className={styles.descriptionImage} src={mission} />
              </span>
            </div>
            <p>
              Focusing on your delight, our VISION is to exceed expectations in
              every interaction. Crafting tailored experiences with esteemed
              brands, we aim to match your journey with the excellence of the
              luxury vehicle you drive away with, now and in the future.
            </p>
          </div>
        </section>
        <section className={`${styles.section4} ${styles.ow}`}>
          {" "}
          <div className={styles.container}>
            <Title bg="ow" className={styles.title}>
              CORE VALUES: OUR DRIVING FORCe
            </Title>
          </div>
          <div className={styles.container}>
            <p>
              At The Autocops, we stand firm on our core values: Integrity,
              Excellence, and Customer-Centricity. These principles guide every
              decision we make, ensuring transparency in all our dealings, a
              commitment to delivering the best in everything we do, and an
              unwavering focus on our customers' needs.
            </p>

            <div className={styles.imgContainer}>
              <span>
                <img className={styles.descriptionImage} src={coreValues} />
              </span>
            </div>
            <p>
              Our core values are the engine that powers our journey towards
              redefining luxury car experiences.
            </p>
          </div>
        </section>
        <section className={styles.section5}>
          <div className={styles.section5TitleContainer}>
            <Title>market marvels</Title>
          </div>
          <Carousel_3d />
        </section>

        {/* <section className={`${styles.section6} ${styles.ow}`}>
          <div className={styles.container}>
            <Title bg="ow" className={styles.title}>
              DEDICATED DETAILING, CERAMIC & PPF CENTRE
            </Title>
          </div>
          <div className={styles.imageContainer}>
            <img src={img} className={styles.cardImage} />{" "}
          </div>
          <div>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Reiciendis eos ducimus nisi amet recusandae voluptatum quidem
              porro dolorem voluptas id sunt, officiis ut expedita architecto at
              delectus quas officia excepturi. <strong>strong text here</strong>
            </p>
          </div>
        </section> */}
        <section className={`${styles.section7} ${styles.ow}`}>
          <div className={styles.section7TitleContainer}>
            <Title bg="ow">Meet The Founders</Title>
          </div>
          <div className={styles.section7CarouselContainer}>
            <MeetTheTeam />
          </div>
        </section>
      </Fade>
      <Footer />
    </>
  );
};
export default AboutUs;
